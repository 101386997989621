import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';


const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileServicesDropdownOpen, setIsMobileServicesDropdownOpen] = useState(false);
  const [isMobileAboutDropdownOpen, setIsMobileAboutDropdownOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || window.pageYOffset;
      setIsSticky(scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const toggleMobileServicesDropdown = () => {
    setIsMobileServicesDropdownOpen(!isMobileServicesDropdownOpen);
  };

  const toggleMobileAboutDropdown = () => {
    setIsMobileAboutDropdownOpen(!isMobileAboutDropdownOpen);
  };

  const isActiveServices = location.pathname.includes('/services') || location.pathname.includes('/website-development') || location.pathname.includes('/seo') || location.pathname.includes('/google-ads');
  const isActiveAbout = location.pathname.includes('/who-we-are') || location.pathname.includes('/why-choose-us') || location.pathname.includes('/careers');

  return (
    <>
      <header className={`transition-all duration-300 ${isSticky ? 'bg-white shadow-sm fixed top-0 left-0 w-full z-50 bg-opacity-100 backdrop-blur-md' : 'bg-gradient-to-bl from-red-100 via-white to-red-100'}`}>
        <div className="container mx-auto px-4 py-2 flex justify-between items-center">
          {/* Left: Clickable Logo */}
          <div className="flex items-center space-x-4">
            <Link to="/">
              <img src="/Images/logo.webp?v=1" alt=" RedSprout Digital Logo" className="h-16" />
            </Link>
            <Link to="/pricing" className="hidden md:inline-flex items-center bg-[#e02b20] text-white px-3 py-1 rounded-full text-sm font-medium hover:bg-red-500 transition-all duration-200 ease-in-out">
      
             social media management from only $99/mo
            </Link>
          </div>

          {/* Center: Nav links */}
          <nav className="hidden md:flex space-x-8 text-gray-800">
            <Link to="/" className={`hover:text-gray-800 ${location.pathname === '/' ? 'text-[#e02b20]' : ''}`} onClick={closeMobileMenu}>
              Home
            </Link>

            {/* About Us Dropdown */}
            <div className="relative group">
              <button className={`hover:text-gray-800 flex items-center ${isActiveAbout ? 'text-[#e02b20]' : ''}`}>
                About Us
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1 transition-transform duration-300 ease-in-out group-hover:rotate-180" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              <div className="absolute left-0 mt-2 w-48 bg-white shadow-lg rounded-md z-20 opacity-0 group-hover:opacity-100 transform scale-y-0 group-hover:scale-y-100 origin-top transition-all duration-300 ease-in-out">
                <Link to="/who-we-are" className={`block px-4 py-2 hover:bg-gray-100 ${location.pathname === '/who-we-are' ? 'text-[#e02b20]' : ''}`}>Who We Are</Link>
                <Link to="/why-choose-us" className={`block px-4 py-2 hover:bg-gray-100 ${location.pathname === '/why-choose-us' ? 'text-[#e02b20]' : ''}`}>Why Choose Us</Link>
                <Link to="/careers" className={`block px-4 py-2 hover:bg-gray-100 ${location.pathname === '/careers' ? 'text-[#e02b20]' : ''}`}>Careers</Link>
              </div>
            </div>

            {/* Services Dropdown */}
            <div className="relative group">
              <button className={`hover:text-gray-800 flex items-center ${isActiveServices ? 'text-[#e02b20]' : ''}`}>
                Services
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1 transition-transform duration-300 ease-in-out group-hover:rotate-180" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              <div className="absolute left-0 mt-2 w-48 bg-white shadow-lg rounded-md z-20 opacity-0 group-hover:opacity-100 transform scale-y-0 group-hover:scale-y-100 origin-top transition-all duration-300 ease-in-out">
                <Link to="/website-development" className={`block px-4 py-2 hover:bg-gray-100 ${location.pathname === '/website-development' ? 'text-[#e02b20]' : ''}`}>Website Development</Link>
                <Link to="/seo" className={`block px-4 py-2 hover:bg-gray-100 ${location.pathname === '/seo' ? 'text-[#e02b20]' : ''}`}>SEO</Link>
                <Link to="/google-ads" className={`block px-4 py-2 hover:bg-gray-100 ${location.pathname === '/google-ads' ? 'text-[#e02b20]' : ''}`}>Google Ads</Link>
              </div>
            </div>

            <Link to="/industries-we-serve" className={`hover:text-gray-800 ${location.pathname.includes('/industries-we-serve') ? 'text-[#e02b20]' : ''}`} onClick={closeMobileMenu}>
              Industries
            </Link>

            <Link to="/contact-us" className={`hover:text-gray-800 ${location.pathname.includes('/contact-us') ? 'text-[#e02b20]' : ''}`} onClick={closeMobileMenu}>
              Contact Us
            </Link>

            <div className="h-6 border-l border-gray-300"></div>

            <Link to="https://clients.redsproutdigital.com/login" className="text-gray-800 hover:text-gray-800" onClick={closeMobileMenu}>
              Sign in
            </Link>
          </nav>

          {/* Right: Get Started */}
          <div className="hidden md:flex items-center space-x-4">
            <Link to="/pricing" className="inline-flex items-center bg-black text-white px-4 py-2 rounded-full font-medium hover:bg-[#e02b20]" onClick={closeMobileMenu}>
              Get Started
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button onClick={toggleMobileMenu} className="text-gray-800 hover:text-gray-800 focus:outline-none">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </div>
      </header>

      {/* Mobile Sidebar Menu */}
      <div className={`fixed inset-0 z-50 transform transition-transform duration-300 ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'} bg-black bg-opacity-50 backdrop-blur-md`} onClick={closeMobileMenu}>
        <div className="absolute left-0 top-0 bottom-0 w-64 bg-white shadow-lg p-6 transform transition-all duration-300 ease-in-out" onClick={(e) => e.stopPropagation()}>
          {/* Sidebar Logo */}
          <div className="flex justify-between items-center mb-6">
            <Link to="/">
              <img src="Images/logo.webp" alt="RedSprout Digital Logo" className="h-16" />
            </Link>
            <button onClick={closeMobileMenu} className="text-gray-600 hover:text-gray-800 focus:outline-none">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          {/* Mobile Menu Items */}
          <nav className="mt-8 flex flex-col space-y-4 text-gray-600">
            <Link to="/" className={`hover:text-gray-800 ${location.pathname === '/' ? 'text-[#e02b20]' : ''}`} onClick={closeMobileMenu}>Home</Link>

            {/* Mobile About Us Dropdown */}
            <div>
              <button onClick={toggleMobileAboutDropdown} className={`w-full text-left flex justify-between items-center hover:text-gray-800 focus:outline-none ${isActiveAbout ? 'text-[#e02b20]' : ''}`}>
                About Us
                <svg xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 ml-1 transition-transform duration-300 ease-in-out ${isMobileAboutDropdownOpen ? 'rotate-180' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              {isMobileAboutDropdownOpen && (
                <div className="ml-4 mt-2 flex flex-col space-y-2">
                  <Link to="/who-we-are" className={`hover:text-gray-800 ${location.pathname === '/who-we-are' ? 'text-[#e02b20]' : ''}`} onClick={closeMobileMenu}>Who We Are</Link>
                  <Link to="/why-choose-us" className={`hover:text-gray-800 ${location.pathname === '/why-choose-us' ? 'text-[#e02b20]' : ''}`} onClick={closeMobileMenu}>Why Choose Us</Link>
                  <Link to="/careers" className={`hover:text-gray-800 ${location.pathname === '/careers' ? 'text-[#e02b20]' : ''}`} onClick={closeMobileMenu}>Careers</Link>
                </div>
              )}
            </div>

            {/* Mobile Services Dropdown */}
            <div>
              <button onClick={toggleMobileServicesDropdown} className={`w-full text-left flex justify-between items-center hover:text-gray-800 focus:outline-none ${isActiveServices ? 'text-[#e02b20]' : ''}`}>
                Services
                <svg xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 ml-1 transition-transform duration-300 ease-in-out ${isMobileServicesDropdownOpen ? 'rotate-180' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              {isMobileServicesDropdownOpen && (
                <div className="ml-4 mt-2 flex flex-col space-y-2">
                  <Link to="/website-development" className={`hover:text-gray-800 ${location.pathname === '/website-development' ? 'text-[#e02b20]' : ''}`} onClick={closeMobileMenu}>Website Development</Link>
                  <Link to="/seo" className={`hover:text-gray-800 ${location.pathname === '/seo' ? 'text-[#e02b20]' : ''}`} onClick={closeMobileMenu}>SEO</Link>
                  <Link to="/google-ads" className={`hover:text-gray-800 ${location.pathname === '/google-ads' ? 'text-[#e02b20]' : ''}`} onClick={closeMobileMenu}>Google Ads</Link>
                </div>
              )}
            </div>

            <Link to="/industries-we-serve" className={`hover:text-gray-800 ${location.pathname.includes('/industries-we-serve') ? 'text-[#e02b20]' : ''}`} onClick={closeMobileMenu}>Industries</Link>
            <Link to="/contact-us" className={`hover:text-gray-800 ${location.pathname.includes('/contact-us') ? 'text-[#e02b20]' : ''}`} onClick={closeMobileMenu}>Contact Us</Link>
            <Link to="https://clients.redsproutdigital.com/login" className="hover:text-gray-800" onClick={closeMobileMenu}>Sign in</Link>
            <Link to="/pricing" className="inline-flex items-center bg-black text-white px-4 py-2 rounded-full font-medium hover:bg-[#e02b20]" onClick={closeMobileMenu}>
              Get Started
            </Link>
          </nav>

          {/* Social Media Links - Force Black */}
          <div className="mt-8">
            <p className="text-gray-600 font-medium mb-2">Follow us:</p>
            <div className="flex space-x-4">
              <a href="https://www.facebook.com/redsproutdigital7" target="_blank" rel="noopener noreferrer">
                <img src="Images/rsicons-facebook.webp" alt="Facebook" className="h-6 w-6 hover:opacity-75 text-black" />
              </a>
              <a href="https://www.instagram.com/digitalredsprout/" target="_blank" rel="noopener noreferrer">
                <img src="Images/rsicons-instagram.webp" alt="Instagram" className="h-6 w-6 hover:opacity-75 text-black" />
              </a>
              <a href="https://www.linkedin.com/company/redsprout-digital" target="_blank" rel="noopener noreferrer">
                <img src="Images/rsicons-linkedin.webp" alt="LinkedIn" className="h-6 w-6 hover:opacity-75 text-black" />
              </a>
            </div>
          </div>

          {/* Footer Text with Heart Pumping */}
          <div className="mt-8 text-gray-500 text-sm">
            <p>
              Made with <span className="animate-pulse text-red-500">❤️</span> by RedSprout Digital
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
