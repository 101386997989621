import React from 'react';
import { Link } from 'react-router-dom';

const ThankYouPage = () => {
  return (
    <div className="h-screen flex items-center justify-center bg-gradient-to-br from-red-100 via-white to-red-100">
      <div className="bg-white p-10 rounded-lg shadow-lg max-w-lg text-center animate-fade-in">
        <h1 className="text-5xl font-bold text-red-600 mb-4">Thank You!</h1>
        <p className="text-gray-700 text-lg mb-6">
          We have received your submission. Our team will contact you within <strong>1 business day</strong>.
        </p>
        <p className="text-red-500 font-bold animate-blink">
          You are just one step away from growing your business!
        </p>
        <p className="text-gray-500 mb-6">
          In the meantime, feel free to explore more about our services or get in touch with us directly.
        </p>
        <Link to="/">
          <button className="px-8 py-3 bg-gradient-to-r from-red-500 via-red-600 to-red-700 text-white rounded-lg shadow-md hover:bg-red-700 transition duration-300">
            Back to Home
          </button>
        </Link>
      </div>

      {/* Inline Tailwind keyframes */}
      <style>
        {`
          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }

          @keyframes blink {
            50% {
              opacity: 0;
            }
          }

          .animate-fade-in {
            animation: fadeIn 1.5s ease-in-out;
          }

          .animate-blink {
            animation: blink 1.5s step-start infinite;
          }
        `}
      </style>
    </div>
  );
};

export default ThankYouPage;
