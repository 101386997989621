import React from 'react';
import { Navigate } from 'react-router-dom';
import { auth } from '..//firebase/Firebase';

const ProtectedRoute = ({ element: Component, ...rest }) => {

  const [loading, setLoading] = React.useState(true);
  const [authenticated, setAuthenticated] = React.useState(false);

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) return <div>Loading...</div>;

  return authenticated ? <Component {...rest} /> : <Navigate to="/admin" />;

};

export default ProtectedRoute;
