import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HeroSection9 from "../assets/industriesweserve/HeroSection9";
import Industriesserve from "..//assets/industriesweserve/Industriesserve";
import CTAsSection from "..//assets/home/CTAsSection";
import Testimonials from "..//assets/home/Testimonials";


const HomePage = () => {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Industries We Serve | RedSprout Digital</title>
          <meta
            name="description"
            content="Discover the industries we serve at RedSprout Digital, from eCommerce to healthcare, Study Abroad and more. Tailored digital marketing solutions by RedSprout Digital."
          />
          <meta
            name="keywords"
            content="industries we serve"
          />
          <meta property="og:title" content="Industries We Serve | RedSprout Digital" />
          <meta property="og:description" content="Discover the industries we serve at RedSprout Digital, from eCommerce to healthcare, Study Abroad and more. Tailored digital marketing solutions by RedSprout Digital." />
          <meta property="og:image" content="/Images/logo.webp?v=1" />
          <meta property="og:url" content="https://redsproutdigital.com/industries-we-serve/" />
          <link rel="canonical" href="https://redsproutdigital.com/industries-we-serve/" />
        </Helmet>
        {/* Hero Section */}
        <HeroSection9 />
        <Industriesserve />
        <CTAsSection />
        <Testimonials />

      </div>
    </HelmetProvider>
  );
};

export default HomePage;
