import React, { useRef, useEffect, useState } from 'react';

const TrustSection = () => {
  const logosRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const logos = logosRef.current;
    if (logos) {
      logos.style.animationPlayState = isHovered ? 'paused' : 'running';
    }
  }, [isHovered]);

  return (
    <>
      {/* Inline CSS for scrolling animation */}
      <style>
        {`
          @keyframes scroll {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-100%);
            }
          }

          .animate-scroll {
            animation: scroll 30s linear infinite; /* Duration adjusted for smooth loop */
          }
        `}
      </style>
      <div className="bg-[#0a0c12] py-12 overflow-hidden">
        <div className="container mx-auto px-4 flex flex-col md:flex-row items-center justify-between">
          {/* Left Section */}
          <div className="w-full md:w-1/3 text-center md:text-left mb-8 md:mb-0">
            <h2 className="text-white text-2xl font-bold">We’ve been transforming some of the most impactful companies.</h2>
            <p className="text-[#9ca3af] text-lg">Our Clientele</p>
          </div>

          {/* Vertical Divider for larger screens only */}
          <div className="hidden md:block border-l-2 border-[#2d2f36] h-16 mx-8"></div>

          {/* Right Section (Logo Carousel) */}
          <div className="relative w-full md:w-2/3 overflow-hidden">
            <div
              ref={logosRef}
              className="flex items-center space-x-8 animate-scroll"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              {/* Logo items - Duplicated for smooth looping */}
              <img
                src="/Images/log-reynaoverseas.webp"
                alt="Reyna Overseas"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-launche.webp"
                alt="Launche Consultants"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-asire.webp"
                alt="Asire Consulting"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-wrapmetro.webp"
                alt="Wrap Metro Institute"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-wrap.webp"
                alt="Wrap Hospitality"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-cliqvenus.webp"
                alt="CliqVenus"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-godirect.webp"
                alt="Go Direct"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-saremvals.webp"
                alt="SA Removals"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-pkbrothers.webp"
                alt="PK Brothers Tours"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-divine.webp"
                alt="Divine Cardinal"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-serverleaf.webp"
                alt="Server Leaf"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-jasper.webp"
                alt="Jasper Consultants"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-rankora.webp"
                alt="Rankora Consulting"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-blue-green.webp"
                alt="Blue Green Exotica"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="Iimages/logo-babshop.webp"
                alt="Baba Shop"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-fusinex.webp"
                alt="Fusinex Tech"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-fusionxinterio.webp"
                alt="FusionX Interio"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              
              {/* Duplicate logos for smooth infinite scrolling */}
              <img
                src="/Images/log-reynaoverseas.webp"
                alt="Reyna Overseas"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-launche.webp"
                alt="Launche Consultants"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-asire.webp"
                alt="Asire Consulting"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-wrapmetro.webp"
                alt="Wrap Metro Institute"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-wrap.webp"
                alt="Wrap Hospitality"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-cliqvenus.webp"
                alt="CliqVenus"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-godirect.webp"
                alt="Go Direct"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-saremvals.webp"
                alt="SA Removals"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-pkbrothers.webp"
                alt="PK Brothers Tours"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-divine.webp"
                alt="Divine Cardinal"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-serverleaf.webp"
                alt="Server Leaf"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-jasper.webp"
                alt="Jasper Consultants"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-rankora.webp"
                alt="Rankora Consulting"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-blue-green.webp"
                alt="Blue Green Exotica"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-babshop.webp"
                alt="Baba Shop"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-fusinex.webp"
                alt="Fusinex Tech"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
              <img
                src="/Images/logo-fusionxinterio.webp"
                alt="FusionX Interio"
                className="h-12 rounded-lg hover:scale-110 transition-transform duration-300"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrustSection;
