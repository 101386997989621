import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const NotFoundPage = () => {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Page Not Found - RedSprout Digital</title> 
          <meta
            name="description"
            content="Sorry, the page you are looking for does not exist. It might have been removed, renamed, or did not exist in the first place."
          />
          <meta
            name="keywords"
            content="why choose redsprout digital, why choose us"
          />
        </Helmet>
    <div className="relative bg-gradient-to-tl from-red-100 via-white to-red-100 min-h-screen flex items-center justify-center text-center">
      <div className="container mx-auto px-4">
        {/* Animated 404 */}
        <div className="text-9xl font-extrabold text-gray-800 mb-8 animate-bounce">
          404
        </div>
        
        {/* Message */}
        <p className="text-xl text-gray-600 mb-6">
          Have a minute? Help us share the love!
        </p>
        <p className="text-lg text-gray-600 mb-8">
          Follow us on Instagram and like us on Facebook to keep you up to date with all our news and announcements.
        </p>

        {/* Social Media Links as Buttons */}
        <div className="flex justify-center space-x-4">
          <a
            href="https://instagram.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="flex items-center space-x-2 bg-pink-500 hover:bg-pink-600 text-white font-bold py-2 px-4 rounded-full transition duration-300">
              <i className="fab fa-instagram"></i>
              <span>Instagram</span>
            </button>
          </a>
          <a
            href="https://facebook.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="flex items-center space-x-2 bg-blue-800 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded-full transition duration-300">
              <i className="fab fa-facebook"></i>
              <span>Facebook</span>
            </button>
          </a>
        </div>
      </div>
    </div>
    </div>
    </HelmetProvider>
  );
};

export default NotFoundPage;
