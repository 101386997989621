import React from 'react';
import { Link } from 'react-router-dom';

const HeroSection = () => {
  return (
    <div className="relative bg-white py-16 overflow-hidden">
      {/* Background Design */}
      <div className="absolute inset-0 bg-gradient-to-br from-red-100 via-white to-red-100">
        <div className="absolute inset-0 bg-grid-pattern opacity-20 z-0"></div>
      </div>

      <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-8 items-center relative z-10">
        {/* Left Side: Text and Buttons */}
        <div className="md:pr-12 animate-fade-slide-in-left">
          <h1 className="text-4xl md:text-6xl font-extrabold text-gray-900 mb-4">
          Affordable Social Media Solutions for Every Business!
          </h1>
          <p className="text-gray-500 mb-6">
          Affordable plans designed to boost your online presence efficiently.
          </p>
          
        </div>

         {/* Right Side: Image */}
         <div className="relative p-10 rounded-lg  max-w-sm w-full mx-auto -mt-10">
            <img
              src="/Images/pricing.webp"
              alt="Careers"
              className="object-cover w-full h-full rounded-lg"
            />
          </div>
      </div>

     
    </div>
  );
};

export default HeroSection;
