import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HeroSection from "../assets/home/HeroSection";
import LogoCarousel from "../assets/home/LogoCarousel";
import Strategy from "../assets/home/Strategy";
import CTASection from "../assets/home/CTAsSection";
import Testimonials from "../assets/home/Testimonials";
import WhyWorkWithUs from "../assets/home/WhyWorkWithUs";
import BottomCTA from "../assets/home/BottomCTA";

const HomePage = () => {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Premier Digital Marketing Agency | RedSprout Digital</title>
          <meta
            name="description"
            content="RedSprout Digital is a premier digital marketing agency offering expert SEO, Web Development , tailored strategies to drive business growth and online success." />
          <meta
            name="keywords"
            content="digital marketing agency, redprout digital, advertising and digital marketing, ppc in digital marketing, digital marketing seo" />
          <meta property="og:title" content="Premier Digital Marketing Agency | RedSprout Digital" />
          <meta property="og:description" content="RedSprout Digital is a premier digital marketing agency offering expert SEO, Web Development , tailored strategies to drive business growth and online success." />
          <meta property="og:image" content="/Images/logo.webp?v=1" />
          <meta property="og:url" content="https://redsproutdigital.com/" />
          <link rel="canonical" href="https://redsproutdigital.com/" />
        </Helmet>

        {/* Hero Section */}
        <HeroSection />

        {/* Logo Carousel Section */}
        <LogoCarousel />

        {/* Strategy Section */}
        <Strategy />

        {/* CTAMiddle Section */}
        <CTASection />

        {/* Testimonials Section */}
        <Testimonials />

        {/* Why Work With Section */}
        <WhyWorkWithUs />

        {/* CTA2 With Section */}
        <BottomCTA />
      </div>
    </HelmetProvider>
  );
};

export default HomePage;
