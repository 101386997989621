import React, { useState, useEffect } from 'react';
import axios from 'axios';  // Import axios to make the API request

const AboutUsSections = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    countryCode: '+1', // Default country code to +1
    phone: '',
    website: '',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);  // Add loading state

  const handleButtonClick = () => {
    setShowPopup(true);
    setSuccessMessage('');
    setErrorMessage('');
  };

  const closePopup = () => {
    setShowPopup(false);
    setSuccessMessage('');
    setErrorMessage('');
    setLoading(false);  // Reset loading state when popup is closed
  };
  // Use useEffect to fetch country code using ipinfo.io API
  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        const response = await axios.get('https://ipinfo.io?token=79dc3892989315');
        const countryCode = mapCountryCodeToPrefix(response.data.country);
        setFormData((prevData) => ({ ...prevData, countryCode }));
      } catch (error) {
        console.error('Error fetching country code:', error);
        setFormData((prevData) => ({ ...prevData, countryCode: '+1' }));
      }
    };
    fetchCountryCode();
  }, []);

  const mapCountryCodeToPrefix = (countryCode) => {
    const countryCodeMap = {
      AF: '+93', AL: '+355', DZ: '+213', AS: '+1-684', AD: '+376', AO: '+244',
      AI: '+1-264', AG: '+1-268', AR: '+54', AM: '+374', AW: '+297', AU: '+61',
      AT: '+43', AZ: '+994', BS: '+1-242', BH: '+973', BD: '+880', BB: '+1-246',
      BY: '+375', BE: '+32', BZ: '+501', BJ: '+229', BM: '+1-441', BT: '+975',
      BO: '+591', BA: '+387', BW: '+267', BR: '+55', IO: '+246', VG: '+1-284',
      BN: '+673', BG: '+359', BF: '+226', BI: '+257', KH: '+855', CM: '+237',
      CA: '+1', CV: '+238', KY: '+1-345', CF: '+236', TD: '+235', CL: '+56',
      CN: '+86', CO: '+57', KM: '+269', CG: '+242', CD: '+243', CK: '+682',
      CR: '+506', CI: '+225', HR: '+385', CU: '+53', CY: '+357', CZ: '+420',
      DK: '+45', DJ: '+253', DM: '+1-767', DO: '+1-809', EC: '+593', EG: '+20',
      SV: '+503', GQ: '+240', ER: '+291', EE: '+372', ET: '+251', FK: '+500',
      FO: '+298', FJ: '+679', FI: '+358', FR: '+33', GF: '+594', PF: '+689',
      GA: '+241', GM: '+220', GE: '+995', DE: '+49', GH: '+233', GI: '+350',
      GR: '+30', GL: '+299', GD: '+1-473', GP: '+590', GU: '+1-671', GT: '+502',
      GG: '+44-1481', GN: '+224', GW: '+245', GY: '+592', HT: '+509', HN: '+504',
      HK: '+852', HU: '+36', IS: '+354', IN: '+91', ID: '+62', IR: '+98',
      IQ: '+964', IE: '+353', IM: '+44-1624', IL: '+972', IT: '+39', JM: '+1-876',
      JP: '+81', JE: '+44-1534', JO: '+962', KZ: '+7', KE: '+254', KI: '+686',
      KP: '+850', KR: '+82', KW: '+965', KG: '+996', LA: '+856', LV: '+371',
      LB: '+961', LS: '+266', LR: '+231', LY: '+218', LI: '+423', LT: '+370',
      LU: '+352', MO: '+853', MK: '+389', MG: '+261', MW: '+265', MY: '+60',
      MV: '+960', ML: '+223', MT: '+356', MH: '+692', MQ: '+596', MR: '+222',
      MU: '+230', YT: '+262', MX: '+52', FM: '+691', MD: '+373', MC: '+377',
      MN: '+976', ME: '+382', MS: '+1-664', MA: '+212', MZ: '+258', MM: '+95',
      NA: '+264', NR: '+674', NP: '+977', NL: '+31', NC: '+687', NZ: '+64',
      NI: '+505', NE: '+227', NG: '+234', NU: '+683', NF: '+672', MP: '+1-670',
      NO: '+47', OM: '+968', PK: '+92', PW: '+680', PS: '+970', PA: '+507',
      PG: '+675', PY: '+595', PE: '+51', PH: '+63', PL: '+48', PT: '+351',
      PR: '+1-787', QA: '+974', RE: '+262', RO: '+40', RU: '+7', RW: '+250',
      SH: '+290', KN: '+1-869', LC: '+1-758', PM: '+508', VC: '+1-784',
      WS: '+685', SM: '+378', ST: '+239', SA: '+966', SN: '+221', RS: '+381',
      SC: '+248', SL: '+232', SG: '+65', SX: '+1-721', SK: '+421', SI: '+386',
      SB: '+677', SO: '+252', ZA: '+27', ES: '+34', LK: '+94', SD: '+249',
      SR: '+597', SZ: '+268', SE: '+46', CH: '+41', SY: '+963', TW: '+886',
      TJ: '+992', TZ: '+255', TH: '+66', TG: '+228', TO: '+676', TT: '+1-868',
      TN: '+216', TR: '+90', TM: '+993', TC: '+1-649', TV: '+688', UG: '+256',
      UA: '+380', AE: '+971', GB: '+44', US: '+1', UY: '+598', UZ: '+998',
      VU: '+678', VA: '+379', VE: '+58', VN: '+84', WF: '+681', YE: '+967',
      ZM: '+260', ZW: '+263'
    };
    return countryCodeMap[countryCode] || '+1'; // Default to +1 if country code not found
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the input is the phone number, ensure only digits are allowed
    if (name === 'phone') {
      if (/^\d*$/.test(value)) {  // Only update the state if the value is numeric
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const validateForm = () => {
    if (!formData.name || !formData.email || !formData.phone || !formData.website) {
      setErrorMessage('All fields are required.');
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setErrorMessage('Please enter a valid email address.');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);  // Set loading to true when form submission starts

    try {
      // Sending the form data to the backend with the subject
      const response = await axios.post('https://5gno3n9tad.execute-api.us-east-1.amazonaws.com/dev/send-email', {
        ...formData,
        subject: 'New Inquiry from RedSprout Digital | Website Development (Pop Up)', // Add subject here
      });
      if (response.status === 200) {
        setSuccessMessage('Your quote request has been submitted successfully!');
        setFormData({ name: '', email: '', phone: '', website: '' });
        setLoading(false);  // Stop spinner once the request is successful
        // Automatically close the popup after 3 seconds
        setTimeout(() => {
          closePopup();
        }, 3000); // 3000 milliseconds = 3 seconds
      } else {
        setErrorMessage('Failed to submit the request. Please try again.');
        setLoading(false);  // Stop spinner if request fails

      }
    } catch (error) {
      console.error('Error submitting form:', error.response ? error.response.data : error.message);
      setErrorMessage('Failed to submit the request. Please try again.');
      setLoading(false);  // Stop spinner if request fails

    }
  };

  return (
    <div>
      {/* Google Ads Section */}
      <div className="relative bg-gradient-to-tr from-red-100 via-white to-red-100 py-16 overflow-hidden">
        <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="relative">
            <img
              src="/Images/success_with_expert_google_ads.webp"
              alt="Elevates Your Success with Expert Google Ads Management"
              className="rounded-lg "
            />
          </div>
          <div className="md:pl-12">
            <h2 className="text-4xl md:text-5xl font-extrabold text-gray-900 mb-4">
              Elevates Your Success with Expert Google Ads Management
            </h2>
            <p className="text-gray-700 mb-6 text-justify">
              A passionate Google Ads agency that delivers more than just the basics. As a top-tier Google Ads agency and trusted partner, RedSprout Digital knows how to unlock the full potential of Google Ads. Our expert services are designed to help you reach a broader, global audience without breaking the bank.
            </p>
            <p className="text-gray-700 mb-6 text-justify">
              But there’s more – we prioritize understanding user intent to craft ads that drive maximum engagement and conversions. With real-time performance metrics, you’ll have instant visibility into your campaign’s success, enabling you to fine-tune your strategy on the go. At RedSprout Digital, we’re not just an agency – we’re your partner in achieving lasting success.
            </p>

            <button
              className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
              onClick={handleButtonClick}
            >
              Get Quote
            </button>
          </div>
        </div>
      </div>

      {/* About Us Section with Text on Left and Image on Right */}
      <div className="relative bg-gradient-to-tl from-red-100 via-white to-red-100 py-16 overflow-hidden">
        <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="md:pr-12">
            <h2 className="text-4xl md:text-5xl font-extrabold text-gray-900 mb-4">
              RedSprout Digital Turns Your Traffic into Conversions
            </h2>
            <p className="text-gray-700 mb-6 text-justify">
              At RedSprout Digital, we don’t just focus on driving traffic – we ensure that it converts. With years of experience and a team of specialists, your PPC campaigns are in expert hands. We’re committed to helping you achieve a lower cost per click while effectively reaching your target audience.
            </p>
            <p className="text-gray-700 mb-6 text-justify">
              ransparency and communication are at the heart of what we do. Expect regular, detailed reports on key metrics like ad spend, clicks, and conversions, along with actionable insights. Our experienced team ensures your campaigns are optimized for the best possible results. Ready to maximize your ROAS? Contact us today!
            </p>
            <button
              className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
              onClick={handleButtonClick}
            >
              Get Quote
            </button>
          </div>
          <div className="relative">
            <img
              src="/Images/turns_your_traffic_into_conversions.webp"
              alt="RedSprout Digital Turns Your Traffic into Conversions"
              className="rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>

      {/* Our Solutions Section */}
      <div className="relative bg-gradient-to-bl from-red-100 via-white to-red-100 py-16 overflow-hidden">
        <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="relative">
            <img
              src="/Images/maximize_your_budget_with_high_impact_google_ads.webp"
              alt="Maximize Your Budget with High-Impact Google Ads"
              className="rounded-lg shadow-lg"
            />
          </div>
          <div className="md:pl-12">
            <h2 className="text-4xl md:text-5xl font-extrabold text-gray-900 mb-4">
              Maximize Your Budget with High-Impact Google Ads
            </h2>
            <p className="text-gray-700 mb-6 text-justify">
              At RedSprout Digital, our experienced Google Ads specialists create ad creatives tailored to your specific audience, ensuring they drive real results. Whether you need ads in various formats or ones that speak directly to your users, we design campaigns that resonate and inspire action.
            </p>
            <p className="text-gray-700 mb-6 text-justify">
              That’s not all – we also provide ad extensions and additional features to make your ads stand out from the competition. With our expert Google Ads services, you can trust that your campaign will be optimized for peak performance.
            </p>
            <button
              className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
              onClick={handleButtonClick}
            >
              Get Quote
            </button>
          </div>
        </div>
      </div>

      {/* About Us Section with Text on Left and Image on Right */}
      <div className="relative bg-gradient-to-tl from-red-100 via-white to-red-100 py-16 overflow-hidden">
        <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="md:pr-12">
            <h2 className="text-4xl md:text-5xl font-extrabold text-gray-900 mb-4">
              Get More Value from Your Advertising Budget
            </h2>
            <p className="text-gray-700 mb-6 text-justify">
              At RedSprout Digital, we take a strategic approach to bidding, customizing our methods to meet your specific needs. By using advanced bidding strategies like target CPA, ROAS, and enhanced CPC, we focus on optimizing costs while continuously refining your campaigns. This approach helps you reach your target audience more effectively and attract high-quality leads.
            </p>
            <p className="text-gray-700 mb-6 text-justify">
              We go beyond basic optimization by leveraging negative keywords to eliminate wasted spend and improve targeting accuracy, ensuring that every dollar of your ad budget is put to good use. With our Google Ads services, you can expect increased website traffic, higher conversion rates, and a stronger ROI. Ready to elevate your marketing? Contact us today!
            </p>
            <button
              className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
              onClick={handleButtonClick}
            >
              Get Quote
            </button>
          </div>
          <div className="relative">
            <img
              src="/Images/more_value_from_your_advertising_budget.webp"
              alt="Get More Value from Your Advertising Budget"
              className="rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>

      {/* Popup Form */}
      {showPopup && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-8 w-full max-w-lg">
            <h2 className="text-2xl font-bold mb-4">Request a Quote</h2>
            {successMessage && <p className="text-green-600 mb-4">{successMessage}</p>}
            {errorMessage && <p className="text-red-600 mb-4">{errorMessage}</p>}
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Your Name"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Your Email"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
                  Phone Number
                </label>

                <div className="flex">
                  <select
                    name="countryCode"
                    value={formData.countryCode}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded-l w-1/4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  >
                    {/* List of country options */}
                    <option value="+93">+93 (Afghanistan)</option>
                    <option value="+355">+355 (Albania)</option>
                    <option value="+213">+213 (Algeria)</option>
                    <option value="+1-684">+1-684 (American Samoa)</option>
                    <option value="+376">+376 (Andorra)</option>
                    <option value="+244">+244 (Angola)</option>
                    <option value="+1-264">+1-264 (Anguilla)</option>
                    <option value="+1-268">+1-268 (Antigua and Barbuda)</option>
                    <option value="+54">+54 (Argentina)</option>
                    <option value="+374">+374 (Armenia)</option>
                    <option value="+297">+297 (Aruba)</option>
                    <option value="+61">+61 (Australia)</option>
                    <option value="+43">+43 (Austria)</option>
                    <option value="+994">+994 (Azerbaijan)</option>
                    <option value="+1-242">+1-242 (Bahamas)</option>
                    <option value="+973">+973 (Bahrain)</option>
                    <option value="+880">+880 (Bangladesh)</option>
                    <option value="+1-246">+1-246 (Barbados)</option>
                    <option value="+375">+375 (Belarus)</option>
                    <option value="+32">+32 (Belgium)</option>
                    <option value="+501">+501 (Belize)</option>
                    <option value="+229">+229 (Benin)</option>
                    <option value="+1-441">+1-441 (Bermuda)</option>
                    <option value="+975">+975 (Bhutan)</option>
                    <option value="+591">+591 (Bolivia)</option>
                    <option value="+387">+387 (Bosnia and Herzegovina)</option>
                    <option value="+267">+267 (Botswana)</option>
                    <option value="+55">+55 (Brazil)</option>
                    <option value="+246">+246 (British Indian Ocean Territory)</option>
                    <option value="+1-284">+1-284 (British Virgin Islands)</option>
                    <option value="+673">+673 (Brunei)</option>
                    <option value="+359">+359 (Bulgaria)</option>
                    <option value="+226">+226 (Burkina Faso)</option>
                    <option value="+257">+257 (Burundi)</option>
                    <option value="+855">+855 (Cambodia)</option>
                    <option value="+237">+237 (Cameroon)</option>
                    <option value="+1">+1 (Canada)</option>
                    <option value="+238">+238 (Cape Verde)</option>
                    <option value="+1-345">+1-345 (Cayman Islands)</option>
                    <option value="+236">+236 (Central African Republic)</option>
                    <option value="+235">+235 (Chad)</option>
                    <option value="+56">+56 (Chile)</option>
                    <option value="+86">+86 (China)</option>
                    <option value="+57">+57 (Colombia)</option>
                    <option value="+269">+269 (Comoros)</option>
                    <option value="+242">+242 (Congo - Brazzaville)</option>
                    <option value="+243">+243 (Congo - Kinshasa)</option>
                    <option value="+682">+682 (Cook Islands)</option>
                    <option value="+506">+506 (Costa Rica)</option>
                    <option value="+225">+225 (Cote d'Ivoire)</option>
                    <option value="+385">+385 (Croatia)</option>
                    <option value="+53">+53 (Cuba)</option>
                    <option value="+357">+357 (Cyprus)</option>
                    <option value="+420">+420 (Czech Republic)</option>
                    <option value="+45">+45 (Denmark)</option>
                    <option value="+253">+253 (Djibouti)</option>
                    <option value="+1-767">+1-767 (Dominica)</option>
                    <option value="+1-809">+1-809 (Dominican Republic)</option>
                    <option value="+593">+593 (Ecuador)</option>
                    <option value="+20">+20 (Egypt)</option>
                    <option value="+503">+503 (El Salvador)</option>
                    <option value="+240">+240 (Equatorial Guinea)</option>
                    <option value="+291">+291 (Eritrea)</option>
                    <option value="+372">+372 (Estonia)</option>
                    <option value="+251">+251 (Ethiopia)</option>
                    <option value="+500">+500 (Falkland Islands)</option>
                    <option value="+298">+298 (Faroe Islands)</option>
                    <option value="+679">+679 (Fiji)</option>
                    <option value="+358">+358 (Finland)</option>
                    <option value="+33">+33 (France)</option>
                    <option value="+594">+594 (French Guiana)</option>
                    <option value="+689">+689 (French Polynesia)</option>
                    <option value="+241">+241 (Gabon)</option>
                    <option value="+220">+220 (Gambia)</option>
                    <option value="+995">+995 (Georgia)</option>
                    <option value="+49">+49 (Germany)</option>
                    <option value="+233">+233 (Ghana)</option>
                    <option value="+350">+350 (Gibraltar)</option>
                    <option value="+30">+30 (Greece)</option>
                    <option value="+299">+299 (Greenland)</option>
                    <option value="+1-473">+1-473 (Grenada)</option>
                    <option value="+590">+590 (Guadeloupe)</option>
                    <option value="+1-671">+1-671 (Guam)</option>
                    <option value="+502">+502 (Guatemala)</option>
                    <option value="+44-1481">+44-1481 (Guernsey)</option>
                    <option value="+224">+224 (Guinea)</option>
                    <option value="+245">+245 (Guinea-Bissau)</option>
                    <option value="+592">+592 (Guyana)</option>
                    <option value="+509">+509 (Haiti)</option>
                    <option value="+504">+504 (Honduras)</option>
                    <option value="+852">+852 (Hong Kong)</option>
                    <option value="+36">+36 (Hungary)</option>
                    <option value="+354">+354 (Iceland)</option>
                    <option value="+91">+91 (India)</option>
                    <option value="+62">+62 (Indonesia)</option>
                    <option value="+98">+98 (Iran)</option>
                    <option value="+964">+964 (Iraq)</option>
                    <option value="+353">+353 (Ireland)</option>
                    <option value="+44-1624">+44-1624 (Isle of Man)</option>
                    <option value="+972">+972 (Israel)</option>
                    <option value="+39">+39 (Italy)</option>
                    <option value="+1-876">+1-876 (Jamaica)</option>
                    <option value="+81">+81 (Japan)</option>
                    <option value="+44-1534">+44-1534 (Jersey)</option>
                    <option value="+962">+962 (Jordan)</option>
                    <option value="+7">+7 (Kazakhstan)</option>
                    <option value="+254">+254 (Kenya)</option>
                    <option value="+686">+686 (Kiribati)</option>
                    <option value="+850">+850 (North Korea)</option>
                    <option value="+82">+82 (South Korea)</option>
                    <option value="+965">+965 (Kuwait)</option>
                    <option value="+996">+996 (Kyrgyzstan)</option>
                    <option value="+856">+856 (Laos)</option>
                    <option value="+371">+371 (Latvia)</option>
                    <option value="+961">+961 (Lebanon)</option>
                    <option value="+266">+266 (Lesotho)</option>
                    <option value="+231">+231 (Liberia)</option>
                    <option value="+218">+218 (Libya)</option>
                    <option value="+423">+423 (Liechtenstein)</option>
                    <option value="+370">+370 (Lithuania)</option>
                    <option value="+352">+352 (Luxembourg)</option>
                    <option value="+853">+853 (Macau)</option>
                    <option value="+389">+389 (Macedonia)</option>
                    <option value="+261">+261 (Madagascar)</option>
                    <option value="+265">+265 (Malawi)</option>
                    <option value="+60">+60 (Malaysia)</option>
                    <option value="+960">+960 (Maldives)</option>
                    <option value="+223">+223 (Mali)</option>
                    <option value="+356">+356 (Malta)</option>
                    <option value="+692">+692 (Marshall Islands)</option>
                    <option value="+596">+596 (Martinique)</option>
                    <option value="+222">+222 (Mauritania)</option>
                    <option value="+230">+230 (Mauritius)</option>
                    <option value="+262">+262 (Mayotte)</option>
                    <option value="+52">+52 (Mexico)</option>
                    <option value="+691">+691 (Micronesia)</option>
                    <option value="+373">+373 (Moldova)</option>
                    <option value="+377">+377 (Monaco)</option>
                    <option value="+976">+976 (Mongolia)</option>
                    <option value="+382">+382 (Montenegro)</option>
                    <option value="+1-664">+1-664 (Montserrat)</option>
                    <option value="+212">+212 (Morocco)</option>
                    <option value="+258">+258 (Mozambique)</option>
                    <option value="+95">+95 (Myanmar)</option>
                    <option value="+264">+264 (Namibia)</option>
                    <option value="+674">+674 (Nauru)</option>
                    <option value="+977">+977 (Nepal)</option>
                    <option value="+31">+31 (Netherlands)</option>
                    <option value="+687">+687 (New Caledonia)</option>
                    <option value="+64">+64 (New Zealand)</option>
                    <option value="+505">+505 (Nicaragua)</option>
                    <option value="+227">+227 (Niger)</option>
                    <option value="+234">+234 (Nigeria)</option>
                    <option value="+683">+683 (Niue)</option>
                    <option value="+672">+672 (Norfolk Island)</option>
                    <option value="+1-670">+1-670 (Northern Mariana Islands)</option>
                    <option value="+47">+47 (Norway)</option>
                    <option value="+968">+968 (Oman)</option>
                    <option value="+92">+92 (Pakistan)</option>
                    <option value="+680">+680 (Palau)</option>
                    <option value="+970">+970 (Palestinian Territory)</option>
                    <option value="+507">+507 (Panama)</option>
                    <option value="+675">+675 (Papua New Guinea)</option>
                    <option value="+595">+595 (Paraguay)</option>
                    <option value="+51">+51 (Peru)</option>
                    <option value="+63">+63 (Philippines)</option>
                    <option value="+48">+48 (Poland)</option>
                    <option value="+351">+351 (Portugal)</option>
                    <option value="+1-787">+1-787 (Puerto Rico)</option>
                    <option value="+974">+974 (Qatar)</option>
                    <option value="+262">+262 (Reunion)</option>
                    <option value="+40">+40 (Romania)</option>
                    <option value="+7">+7 (Russia)</option>
                    <option value="+250">+250 (Rwanda)</option>
                    <option value="+290">+290 (Saint Helena)</option>
                    <option value="+1-869">+1-869 (Saint Kitts and Nevis)</option>
                    <option value="+1-758">+1-758 (Saint Lucia)</option>
                    <option value="+508">+508 (Saint Pierre and Miquelon)</option>
                    <option value="+1-784">+1-784 (Saint Vincent and the Grenadines)</option>
                    <option value="+685">+685 (Samoa)</option>
                    <option value="+378">+378 (San Marino)</option>
                    <option value="+239">+239 (Sao Tome and Principe)</option>
                    <option value="+966">+966 (Saudi Arabia)</option>
                    <option value="+221">+221 (Senegal)</option>
                    <option value="+381">+381 (Serbia)</option>
                    <option value="+248">+248 (Seychelles)</option>
                    <option value="+232">+232 (Sierra Leone)</option>
                    <option value="+65">+65 (Singapore)</option>
                    <option value="+1-721">+1-721 (Sint Maarten)</option>
                    <option value="+421">+421 (Slovakia)</option>
                    <option value="+386">+386 (Slovenia)</option>
                    <option value="+677">+677 (Solomon Islands)</option>
                    <option value="+252">+252 (Somalia)</option>
                    <option value="+27">+27 (South Africa)</option>
                    <option value="+34">+34 (Spain)</option>
                    <option value="+94">+94 (Sri Lanka)</option>
                    <option value="+249">+249 (Sudan)</option>
                    <option value="+597">+597 (Suriname)</option>
                    <option value="+268">+268 (Swaziland)</option>
                    <option value="+46">+46 (Sweden)</option>
                    <option value="+41">+41 (Switzerland)</option>
                    <option value="+963">+963 (Syria)</option>
                    <option value="+886">+886 (Taiwan)</option>
                    <option value="+992">+992 (Tajikistan)</option>
                    <option value="+255">+255 (Tanzania)</option>
                    <option value="+66">+66 (Thailand)</option>
                    <option value="+228">+228 (Togo)</option>
                    <option value="+676">+676 (Tonga)</option>
                    <option value="+1-868">+1-868 (Trinidad and Tobago)</option>
                    <option value="+216">+216 (Tunisia)</option>
                    <option value="+90">+90 (Turkey)</option>
                    <option value="+993">+993 (Turkmenistan)</option>
                    <option value="+1-649">+1-649 (Turks and Caicos Islands)</option>
                    <option value="+688">+688 (Tuvalu)</option>
                    <option value="+256">+256 (Uganda)</option>
                    <option value="+380">+380 (Ukraine)</option>
                    <option value="+971">+971 (United Arab Emirates)</option>
                    <option value="+44">+44 (United Kingdom)</option>
                    <option value="+1">+1 (United States)</option>
                    <option value="+598">+598 (Uruguay)</option>
                    <option value="+998">+998 (Uzbekistan)</option>
                    <option value="+678">+678 (Vanuatu)</option>
                    <option value="+379">+379 (Vatican City)</option>
                    <option value="+58">+58 (Venezuela)</option>
                    <option value="+84">+84 (Vietnam)</option>
                    <option value="+681">+681 (Wallis and Futuna)</option>
                    <option value="+967">+967 (Yemen)</option>
                    <option value="+260">+260 (Zambia)</option>
                    <option value="+263">+263 (Zimbabwe)</option>

                    {/* Continue country codes here */}
                  </select>

                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded-r w-3/4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="234 567 8901"
                    required
                  />
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="website">
                  Website
                </label>
                <input
                  type="url"
                  id="website"
                  name="website"
                  value={formData.website}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="https://yourwebsite.com"
                  required
                />
              </div>
              <div className="flex justify-between items-center">
                <button
                  type="submit"
                  className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
                  disabled={loading}  // Disable the button when loading
                >
                  {loading ? (
                    <svg className="animate-spin h-5 w-5 mr-3 inline-block text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                    </svg>
                  ) : (
                    'Submit'
                  )}
                </button>
                <button
                  type="button"
                  className="text-gray-600 hover:text-gray-900 transition duration-300"
                  onClick={closePopup}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AboutUsSections;
