import React, { useState, useEffect, useRef } from 'react';

const TestimonialsSection = () => {
  const testimonials = [
    {
      avatar: '/Images/avatarmale.webp',
      name: 'Nitesh Sethi',
      title: 'CEO, CliqVenus Consulting and Services LLP',
      feedback: `RedSprout Digital has completely transformed our organization’s online presence. Their strategic web design and social media management have significantly boosted our brand visibility, positioning us as a leader in the industry. The results are outstanding!`,
      titleFeedback: 'Awesome!',
    },
    {
      avatar: '/Images/avtarfemale.webp',
      name: 'Neha',
      title: 'Admin, Asire Consulting',
      feedback: `RedSprout Digital completely transformed our online marketing efforts. Their strategic insights into our brand have driven unprecedented traffic to our site, significantly boosting engagement, conversions, and brand loyalty. Their dedicated and talented team is absolutely outstanding!`,
      titleFeedback: 'Highly Recommended!',
    },
    {
      avatar: '/Images/avatarmale.webp',
      name: 'Rajesh Dhaktode',
      title: 'Marketing Director, WRap Metro Hospitality',
      feedback: `RedSprout Digital amplified our brand's voice with highly effective social media campaigns and creative content strategies. Their exceptional work has driven significant business growth, boosting our visibility and engagement. We highly recommend them for impactful digital marketing solutions!`,
      titleFeedback: 'Fantastic Work!',
    },
    {
      avatar: '/Images/avtarfemale.webp',
      name: 'Lipsa',
      title: 'Founder, FusionX Interio',
      feedback: `Working with RedSprout Digital has been a game-changer for our business. Their innovative approach to website development has elevated our online presence, keeping us ahead of the competition and consistently delivering exceptional results for our brand.`,
      titleFeedback: 'Game-Changer!',
    },
    {
      avatar: '/Images/avatarmale.webp',
      name: 'Ravi',
      title: 'Founder & CEO, Fuison Tech',
      feedback: `The team at RedSprout Digital is highly skilled and focused on delivering results. They expertly optimized our online presence, driving a significant increase in customer engagement and helping us achieve a stronger, more impactful digital strategy.`,
      titleFeedback: 'Incredibly Talented!',
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimated, setIsAnimated] = useState(false);
  const sectionRef = useRef(null);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsAnimated(true);
          }
        });
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={sectionRef}
      className="bg-gradient-to-br from-red-100 via-white to-red-100 py-16"
    >
      <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center">
        {/* Left Section */}
        <div className="md:w-1/2 text-center md:text-left mb-8 md:mb-0">
          <h3 className="text-sm uppercase text-gray-500 mb-2">Testimonials</h3>
          <h2 className="text-4xl font-bold text-black leading-tight mb-4">
            See Why Clients{' '}
            <span className={`text-red-600 inline-block ${isAnimated ? 'animate-heartFade' : ''}`}>
              ❤️
            </span>{' '}
            RedSprout Digital
          </h2>
        </div>

        {/* Right Section: Testimonials Carousel */}
        <div className="md:w-1/2 flex flex-col items-center relative">
          <div
            className="w-full bg-white p-6 rounded-lg shadow-lg transition-transform duration-500 ease-in-out transform"
          >
            <div className="text-center">
              <img
                src={testimonials[currentIndex].avatar}
                alt={testimonials[currentIndex].name}
                className="w-16 h-16 rounded-full mx-auto mb-4 shadow-md"
              />
              <h4 className="text-xl font-semibold text-black mb-2">{testimonials[currentIndex].titleFeedback}</h4>
              <p className="text-gray-700 mb-4">{testimonials[currentIndex].feedback}</p>
              <p className="font-semibold text-black">{testimonials[currentIndex].name}</p>
              <p className="text-gray-500">{testimonials[currentIndex].title}</p>
            </div>
          </div>

          {/* Pagination and Controls */}
          <div className="flex justify-center items-center mt-4">
            <button onClick={handlePrev} className="mx-2 text-xl hover:text-red-600">←</button>
            <span className="mx-4">
              {currentIndex + 1} / {testimonials.length}
            </span>
            <button onClick={handleNext} className="mx-2 text-xl hover:text-red-600">→</button>
          </div>
        </div>
      </div>

      {/* Inline CSS for the animation */}
      <style>
        {`
          @keyframes fadeInLeft {
            0% {
              opacity: 0;
              transform: translateX(-50px);
            }
            100% {
              opacity: 1;
              transform: translateX(0);
            }
          }

          .animate-heartFade {
            animation: fadeInLeft 1s ease-out;
          }
        `}
      </style>
    </div>
  );
};

export default TestimonialsSection;
