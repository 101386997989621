import React, { useState } from 'react';

const CapabilitiesSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="relative bg-gradient-to-br from-white via-red-100 to-white py-16">
      <div className="container mx-auto px-4 md:px-8 flex flex-col md:flex-row items-center justify-between">
        
        {/* Left Section: Text */}
        <div className="md:w-1/2 mb-8 md:mb-0 pr-8"> {/* Added padding-right */}
          <h3 className="text-sm uppercase text-gray-600 mb-2">Capabilities</h3>
          <h2 className="text-4xl font-bold text-gray-900 leading-tight mb-4">
            We’re a results-focused team.
          </h2>
          <p className="text-gray-700 leading-relaxed mb-4 text-justify">
            At RedSprout, we don’t offer one-size-fits-all packages. Unlike agencies that prioritize their own profits, we focus on understanding your unique goals and challenges. We’ll assess what you want to achieve, identify what sets your business apart, analyze your key competitors, and review your current performance. From there, we’ll create a tailored solution that aligns with your objectives.
          </p>
          <p className="text-gray-700 leading-relaxed mb-4 text-justify">
            We believe in delivering meaningful results that truly impact your business. Our goal is to help you gain more customers and increase sales. If you’re looking for a partner who genuinely cares about your success, we’d love to hear from you.
          </p>
          <p className="text-gray-700 leading-relaxed text-justify">
            We believe in delivering meaningful results that truly impact your business.
          </p>

          {/* Accordion Section */}
          <div className="mt-8">
            {/* Item 1 */}
            <div className="w-[80%] mx-auto border-b border-gray-600">
              <button
                className="flex justify-between items-center w-full py-3 text-left focus:outline-none"
                onClick={() => handleToggle(0)}
              >
                <span className="text-base font-semibold">
                  Tailored Digital Solutions
                </span>
                <span className="text-lg">
                  {activeIndex === 0 ? '−' : '+'}
                </span>
              </button>
              {activeIndex === 0 && (
                <div className="pl-4 pb-3 text-sm text-gray-600">
                  <p>
                    One size doesn’t work for everyone. We customize digital marketing strategies to align with your specific goals and challenges.
                  </p>
                </div>
              )}
            </div>

            {/* Item 2 */}
            <div className="w-[80%] mx-auto border-b border-gray-600">
              <button
                className="flex justify-between items-center w-full py-3 text-left focus:outline-none"
                onClick={() => handleToggle(1)}
              >
                <span className="text-base font-semibold">
                  Human-Powered, Data-Driven Solutions
                </span>
                <span className="text-lg">
                  {activeIndex === 1 ? '−' : '+'}
                </span>
              </button>
              {activeIndex === 1 && (
                <div className="pl-4 pb-3 text-sm text-gray-600">
                  <p>
                    Our approach combines insights and creativity to deliver results that truly matter for your business.
                  </p>
                </div>
              )}
            </div>

            {/* Item 3 */}
            <div className="w-[80%] mx-auto border-b border-gray-600">
              <button
                className="flex justify-between items-center w-full py-3 text-left focus:outline-none"
                onClick={() => handleToggle(2)}
              >
                <span className="text-base font-semibold">
                  Expert Teams for Success
                </span>
                <span className="text-lg">
                  {activeIndex === 2 ? '−' : '+'}
                </span>
              </button>
              {activeIndex === 2 && (
                <div className="pl-4 pb-3 text-sm text-gray-600">
                  <p>
                    Our expert teams help you succeed by providing tailored advice and cutting-edge strategies that are designed to grow your business.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Right Section: Image */}
        <div className="md:w-1/2 relative">
          <img
            src="/Images/workwithus.webp"
            alt="Capabilities"
            className="rounded-lg shadow-lg w-full h-full object-cover"  // Adjusted height
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="bg-white p-4 rounded-full shadow-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-10 w-10 text-red-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm-2-11.5v7l6-3.5-6-3.5z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CapabilitiesSection;
