import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);
  const { pathname } = useLocation(); // Get the current route pathname

  // Show the button when the user scrolls down 20% of the page
  const toggleVisibility = () => {
    if (window.scrollY > window.innerHeight * 0.2) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Calculate scroll progress for the line filling
  const updateScrollProgress = () => {
    const scrollTop = window.scrollY;
    const windowHeight = document.documentElement.scrollHeight - window.innerHeight;
    const scrollPercent = (scrollTop / windowHeight) * 100;
    setScrollProgress(scrollPercent);
  };

  // Scroll to top smoothly
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // Scroll to top when the pathname changes (new page navigation)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]); // Scroll to top whenever the route changes

  // Scroll and visibility listeners
  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    window.addEventListener('scroll', updateScrollProgress);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
      window.removeEventListener('scroll', updateScrollProgress);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <div className="fixed bottom-4 right-0 z-50 flex flex-col items-center"> {/* Ensure everything is aligned vertically */}
          <button
            onClick={scrollToTop}
            className="focus:outline-none"
            aria-label="Scroll to Top"
          >
            <span className="text-red-600 font-bold text-lg tracking-wide mb-2" style={{ writingMode: 'vertical-rl', textOrientation: 'mixed' }}>
              Scroll to top
            </span>
          </button>
          <div className="relative w-1 h-24 bg-gray-300"> {/* Line below the text */}
            <div
              style={{ height: `${scrollProgress}%` }}
              className="absolute bottom-0 left-0 w-full bg-red-600 transition-all"
            ></div>
          </div>
        </div>
      )}
    </>
  );
};

export default ScrollToTop;
