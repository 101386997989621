import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HeroSection3 from "../assets/whychooseus/HeroSection3";
import Count from "../assets/whychooseus/Count";
import CTAsSection from "..//assets/home/CTAsSection";
import Testimonials from "..//assets/home/Testimonials";

const WhyChooseUs = () => {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Why Choose RedSprout Digital | Digital Marketing Agency</title>
          <meta
            name="description"
            content="Why Choose RedSprout Digital - the top choice for businesses seeking expert digital marketing solutions, including SEO, PPC, social media strategies for growth."
          />
          <meta
            name="keywords"
            content="why choose redsprout digital, why choose us"
          />
          <meta property="og:title" content="Why Choose RedSprout Digital | Digital Marketing Agency" />
          <meta property="og:description" content="Why Choose RedSprout Digital - the top choice for businesses seeking expert digital marketing solutions, including SEO, PPC, social media strategies for growth." />
          <meta property="og:image" content="/Images/logo.webp?v=1" />
          <meta property="og:url" content="https://redsproutdigital.com/why-choose-us/" />
          <link rel="canonical" href="https://redsproutdigital.com/why-choose-us/" />
        </Helmet>
        {/* Hero Section */}
        <HeroSection3 />
        <Count />
        <CTAsSection />
        <Testimonials />


      </div>
    </HelmetProvider>

  );
};

export default WhyChooseUs;