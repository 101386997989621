import React from 'react';

const HeroSection = () => {
  return (
    <div className="relative bg-white py-16 overflow-hidden">
      {/* Background Design */}
      <div className="absolute inset-0 bg-gradient-to-br from-red-100 via-white to-red-100">
        <div className="absolute inset-0 bg-grid-pattern opacity-20 z-0"></div>
      </div>

      <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-8 items-center relative z-10">
        {/* Left Side: Text and Buttons */}
        <div className="md:pr-12 animate-fade-slide-in-left">
          <h1 className="text-4xl md:text-6xl font-extrabold text-gray-900 mb-4">
            Blogs - Dive into the World of Digital Marketing!
          </h1>
          <p className="text-gray-500 mb-6">
            Dive into our expert-led blog and discover the latest trends, strategies, and insights that are transforming the digital marketing landscape.
          </p>

        </div>

        {/* Right Side: Animated Graph Line */}
        <div className="flex justify-center items-center">
          <svg width="100%" height="200" viewBox="0 0 100 100" preserveAspectRatio="none">
            <polyline
              points="0,100 20,80 40,90 60,60 80,30 100,10"
              fill="none"
              stroke="red"
              strokeWidth="2"
              className="animate-draw-line"
            />
          </svg>
        </div>
      </div>

      <style jsx>{`
        @keyframes draw-line {
          from {
            stroke-dasharray: 0, 200;
          }
          to {
            stroke-dasharray: 200, 200;
          }
        }

        .animate-draw-line {
          animation: draw-line 3s ease-in-out forwards;
          stroke-dasharray: 0, 200;
        }
      `}</style>
    </div>
  );
};

export default HeroSection;
