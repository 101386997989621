import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HeroSection5 from "../assets/webdevelopment/HeroSection5";
import WebContent from "../assets/webdevelopment/WebContent";
import BottomCTA from "../assets/home/BottomCTA";


const HomePage = () => {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Website Development | Build Your Perfect Website with RedSprout Digital</title>
          <meta
            name="description"
            content="Custom website development tailored to your business. RedSprout Digital builds fast, responsive, and stunning websites to boost your online presence." />
          <meta
            name="keywords"
            content="home, my website, services, offerings" />
          <meta property="og:title" content="Website Development | Build Your Perfect Website with RedSprout Digital" />
          <meta property="og:description" content="Custom website development tailored to your business. RedSprout Digital builds fast, responsive, and stunning websites to boost your online presence." />
          <meta property="og:image" content="/Images/logo.webp?v=1" />
          <meta property="og:url" content="https://redsproutdigital.com/website-development/" />
          <link rel="canonical" href="https://redsproutdigital.com/website-development/" />
        </Helmet>
        {/* Hero Section */}
        <HeroSection5 />
        <WebContent />
        <BottomCTA />


      </div>
    </HelmetProvider>
  );
};

export default HomePage;
