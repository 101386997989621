import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HeroSection6 from "../assets/seo/HeroSection6";
import SeoContent from "..//assets/seo/SeoContent";
import BottomCTA from "..//assets/home/BottomCTA";


const HomePage = () => {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>SEO Services | Boost Your Rankings with RedSprout Digital</title>
          <meta
            name="description"
            content="RedSprout Digital offers expert SEO services to improve your website’s rankings, drive traffic, grow your business online. Get results-driven SEO solutions today!"
          />
          <meta
            name="keywords"
            content="seo, seo services, seo optimisation, search optimization, seo agency"
          />
          <meta property="og:title" content="SEO Services | Boost Your Rankings with RedSprout Digital" />
          <meta property="og:description" content="RedSprout Digital offers expert SEO services to improve your website’s rankings, drive traffic, grow your business online. Get results-driven SEO solutions today!" />
          <meta property="og:image" content="/Images/logo.webp?v=1" />
          <meta property="og:url" content="https://redsproutdigital.com/seo/" />
          <link rel="canonical" href="https://redsproutdigital.com/seo/" />
        </Helmet>
        {/* Hero Section */}
        <HeroSection6 />
        <SeoContent />
        <BottomCTA />


      </div>
    </HelmetProvider>
  );
};

export default HomePage;