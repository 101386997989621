import React, { useState } from 'react';
import { auth } from '../firebase/Firebase';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';

const AdminLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/admin/dashboard');
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div className="relative bg-gradient-to-tl from-red-100 via-white to-red-100 min-h-screen flex items-center justify-center p-4">
      <div className="flex flex-col md:flex-row w-full max-w-4xl bg-white shadow-lg rounded-lg overflow-hidden">
        {/* Left Section (Image Only) */}
        <div className="md:w-1/2 w-full bg-black  flex justify-center items-center">
          <img
            src="/Images/whoweare.webp"
            alt="Blog Management"
            className="object-cover w-full h-full"
          />
        </div>

        {/* Right Section (Login Form) */}
        <div className="md:w-1/2 w-full p-8">
          <form onSubmit={handleLogin} className="w-full">
            <h2 className="text-2xl mb-6 text-center">Admin Login</h2>
            <div className="mb-4">
              <label className="block text-gray-700">Email:</label>
              <input
                type="email"
                className="w-full px-3 py-2 border rounded"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700">Password:</label>
              <input
                type="password"
                className="w-full px-3 py-2 border rounded"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-gradient-to-r from-red-500 via-red-600 to-red-700 text-white py-2 rounded hover:opacity-90 transition duration-300"
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
