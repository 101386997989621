import React from 'react';

const BottomCTA = () => {
  return (
    <>
      <div className="bg-gray-900 pt-16">
        <div className="container mx-auto px-4 md:px-8 flex flex-col md:flex-row justify-between items-center">
          {/* Left Section: Text */}
          <div className="md:w-3/4 mb-8 md:mb-0">
            <h3 className="text-sm uppercase text-gray-500 mb-2">Let's Talk</h3>
            <h2 className="text-4xl font-bold text-white leading-tight mb-4">
              Ready to go to the next level?
            </h2>
            <p className="text text-gray-300">
              Are you ready to discover how RedSprout Digital can help your business grow? Or do you have some questions about who we are and what we offer? Either way, we’d love to hear from you! <span className="font-semibold text-white">Contact us today and let's discuss your next big step.</span>
            </p>
          </div>

          {/* Right Section: CTA Button */}
          <div className="md:w-1/4 flex justify-end">
            <a
              href="/contact-us"
              className="inline-flex items-center justify-center px-8 py-3 border border-white text-white text-lg font-medium rounded-lg hover:bg-white hover:text-gray-900 transition duration-300"
            >
              Contact Us →
            </a>
          </div>
        </div>
      </div>

      <div className="relative bg-gray-900 py-16">
  {/* Full Width Image */}
  <div className="relative w-full h-72 md:h-[400px] overflow-hidden">
    <img
      src="/Images/projects.webp" // Replace with your image path
      alt="Team Background"
      className="object-cover w-full h-full object-top" // Adjusted to push the image down slightly
    />

    {/* Info Cards positioned in the bottom-right corner */}
    <div className="absolute bottom-0 right-0 flex">
      <div className="bg-red-600 text-white p-6 flex flex-col justify-center items-center w-[200px] h-[150px] md:w-[250px] md:h-[180px] animation-fade-in">
        <div className="text-5xl font-bold mb-2">485+</div>
        <div className="text-lg text-center leading-tight">Succeeded<br />Projects</div>
        <div className="flex space-x-1 mt-2">
          <span className="bg-white h-2 w-2 rounded-full"></span>
          <span className="bg-white h-2 w-2 rounded-full"></span>
          <span className="bg-white h-2 w-2 rounded-full"></span>
        </div>
      </div>
      <div className="bg-black text-white p-6 flex flex-col justify-center items-center w-[200px] h-[150px] md:w-[250px] md:h-[180px] animation-fade-in">
        <div className="text-5xl font-bold mb-2">5346+</div>
        <div className="text-lg text-center leading-tight">Working Hours<br />Were Spent</div>
        <div className="flex space-x-1 mt-2">
          <span className="bg-white h-2 w-2 rounded-full"></span>
          <span className="bg-white h-2 w-2 rounded-full"></span>
          <span className="bg-white h-2 w-2 rounded-full"></span>
        </div>
      </div>
    </div>
  </div>
</div>


    


    
    </>
  );
};

export default BottomCTA;
