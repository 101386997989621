import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HeroSection4 from "../assets/careers/HeroSection4";
import JobsOppotunities from "..//assets/careers/JobsOppotunities";


const WhyChooseUs = () => {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Careers at RedSprout Digital | Join Our Digital Marketing Team</title>
          <meta
            name="description"
            content="Explore exciting careers opportunities at RedSprout Digital. Join our dynamic team and help shape the future of digital marketing, SEO, PPC, and web development."
          />
          <meta
            name="keywords"
            content="careers, digital marketing careers at RedSprout Digital"
          />
          <meta property="og:title" content="Careers at RedSprout Digital | Join Our Digital Marketing Team" />
          <meta property="og:description" content="Explore exciting careers opportunities at RedSprout Digital. Join our dynamic team and help shape the future of digital marketing, SEO, PPC, and web development." />
          <meta property="og:image" content="/Images/logo.webp?v=1" />
          <meta property="og:url" content="https://redsproutdigital.com/careers/" />
          <link rel="canonical" href="https://redsproutdigital.com/careers/" />
        </Helmet>

        {/* Hero Section */}
        <HeroSection4 />
        <JobsOppotunities />



      </div>
    </HelmetProvider>
  );
};

export default WhyChooseUs;