import React, { useEffect, useState } from 'react';

const CountingSection = () => {
  const stats = [
    { id: 1, value: 36, title: 'App Designs', description: 'Creating a full range UI/UX apps across the whole platform.' },
    { id: 2, value: 47, title: 'Markets Campaigns', description: 'We have strict intentions to completely change the way of UI/UX creation.' },
    { id: 3, value: 29, title: 'API Integrations', description: 'Software-enabled platforms to completely change the way of UI/UX iterations.' },
    { id: 4, value: 29, title: 'API Integrations', description: 'Software-enabled platforms to completely change the way of UI/UX iterations.' },
  ];

  const [counts, setCounts] = useState(stats.map(() => 0));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounts((prevCounts) =>
        prevCounts.map((count, index) =>
          count < stats[index].value ? count + 1 : count
        )
      );
    }, 50);

    return () => clearInterval(intervalId);
  }, [stats]);

  return (
    <div className="bg-gradient-to-tr from-red-100 via-white to-red-100 p-10 text-gray-900">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-10 items-center">
        <div className="col-span-1 md:col-span-1">
          <h4 className="text-gray-700 text-lg uppercase tracking-widest mb-2">Statistics</h4>
          <h2 className="text-5xl font-bold">Project by the numbers.</h2>
        </div>
        <div className="col-span-1 md:col-span-2 grid grid-cols-1 sm:grid-cols-2 gap-10">
          {stats.map((stat, index) => (
            <div key={stat.id} className="flex flex-col">
              <div className="flex items-center">
                <div className="mr-3">
                  <div className="grid grid-cols-3 gap-1">
                    <div className="w-1.5 h-1.5 bg-gray-700 rounded-full"></div>
                    <div className="w-1.5 h-1.5 bg-gray-700 rounded-full"></div>
                    <div className="w-1.5 h-1.5 bg-gray-700 rounded-full"></div>
                    <div className="w-1.5 h-1.5 bg-gray-700 rounded-full"></div>
                    <div className="w-1.5 h-1.5 bg-gray-700 rounded-full"></div>
                    <div className="w-1.5 h-1.5 bg-gray-700 rounded-full"></div>
                    <div className="w-1.5 h-1.5 bg-gray-700 rounded-full"></div>
                    <div className="w-1.5 h-1.5 bg-gray-700 rounded-full"></div>
                    <div className="w-1.5 h-1.5 bg-gray-700 rounded-full"></div>
                  </div>
                </div>
                <span className="text-5xl font-bold animate-pulse">{counts[index]}</span>
                <span className="ml-2 text-xl font-semibold">{stat.title}</span>
              </div>
              <p className="text-gray-700 mt-2">{stat.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CountingSection;
