import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const PricingTabs = () => {
  const [selectedCategory, setSelectedCategory] = useState('Social Media Posts');
  const [selectedPlan, setSelectedPlan] = useState('Growth Accelerator');

  const categories = {
    'Social Media Posts': [
      {
        name: 'Sprout Starter',
        price: '₹6999',
        period: '/mo',
        posts: '10 social media posts per month',
        description: [
          '10 custom social media posts per month',
          'Posts designed in your unique branding',
          'Captions & hashtags crafted to increase engagement',
          'We will schedule the posts on your behalf',
          '3 social media channels included',
          '$10/month for each additional channel',
          'Access to analytics & dashboard',
        ],
      },
      {
        name: 'Growth Accelerator',
        price: '₹8999',
        period: '/mo',
        posts: '15 social media posts per month',
        description: [
          '15 custom social media posts per month',
          'Tailored branding and content strategy',
          'Captions & trending hashtags for optimal visibility',
          'Posts scheduled for you (optional)',
          '3 social media channels included',
          '$10/month for each additional channel',
          'Comprehensive analytics & dashboard access',
        ],
        popular: true,
      },
      {
        name: 'Brand Dominator',
        price: '₹14999',
        period: '/mo',
        posts: '30 social media posts per month',
        description: [
          '30 custom social media posts per month',
          'Fully tailored posts to fit your brand’s identity',
          'Strategic captions & hashtags to drive engagement',
          'Posts scheduled for you (optional)',
          '3 social media channels included',
          '$10/month for each additional channel',
          'Full analytics & performance dashboard',
        ],
      },
    ],
  };

  const plans = categories[selectedCategory];

  const popularButtonStyle = {
    backgroundColor: 'black',
    color: 'white',
    fontWeight: 'bold',
    padding: '2px 8px',
    borderRadius: '9999px',
    textTransform: 'uppercase',
    fontSize: '10px',
    marginLeft: '8px',
    display: 'inline-block',
  };

  const priceStyleLeftAlign = {
    fontSize: '1.75rem',
    fontWeight: 'bold',
    color: 'black',
    textAlign: 'left',
    paddingLeft: '0',
  };

  const periodStyle = {
    fontSize: '1rem',
    fontWeight: 'normal',
    color: 'black',
  };

  const buttonStyle = {
    background: 'linear-gradient(to right, #f56565, #e53e3e)',
    color: 'white',
    fontWeight: 'bold',
    padding: '12px 24px',
    borderRadius: '9999px',
    display: 'inline-block',
    textAlign: 'center',
    transition: 'all 0.3s ease-in-out',
  };

  const circleStyle = {
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    border: '2px solid #f56565',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '8px',
  };

  const filledDotStyle = {
    height: '10px',
    width: '10px',
    backgroundColor: '#f56565',
    borderRadius: '50%',
  };

  // CSS animation for blinking effect
  const blinkAnimation = {
    animation: 'blink 1s infinite', // Blinks every second
  };

  return (
    <section className="w-full bg-gradient-to-tr from-red-100 via-white to-red-100 text-gray-900 py-8 md:py-12 px-4">
      <div className="text-center mb-6 md:mb-8">
        <h1 className="text-3xl md:text-4xl font-extrabold mb-4 text-black">
          Affordable Pricing
        </h1>
        <p className="text-md md:text-lg text-gray-600">
          Select how much content you need each month. Cancel anytime.
        </p>
      </div>

      {/* Category Tabs */}
      <div className="flex flex-wrap justify-center space-x-2 md:space-x-4 mb-6 md:mb-8">
        {Object.keys(categories).map((category) => (
          <button
            key={category}
            onClick={() => {
              setSelectedCategory(category);
              setSelectedPlan(categories[category][0].name);
            }}
            className={`px-4 md:px-6 py-2 rounded-full font-bold text-sm md:text-base ${
              selectedCategory === category
                ? 'bg-black text-white'
                : 'bg-gray-200 text-gray-600'
            }`}
          >
            {category}
          </button>
        ))}
      </div>

      {/* Festive Offer Section */}
      <div className="text-center mb-6">
        <p
          className="text-lg font-semibold text-red-600"
          style={blinkAnimation} // Applying blinking animation
        >
          Festive Season Offer Starts from ₹6999/month
        </p>
      </div>

      <div className="flex flex-col md:flex-row justify-center px-4 md:px-20 space-y-4 md:space-y-0">
        {/* Tabs for Plan Selection */}
        <div className="flex flex-col w-full md:w-3/5 space-y-4 text-left">
          {plans.map((plan) => (
            <button
              key={plan.name}
              onClick={() => setSelectedPlan(plan.name)}
              className={`relative text-left w-full border ${
                selectedPlan === plan.name
                  ? 'border-red-600 bg-red-50'
                  : 'border-gray-300'
              } p-4 rounded-md transition-all duration-300 ease-in-out hover:border-red-600`}
            >
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  {/* Blank circle that fills with a dot when selected */}
                  <div style={circleStyle}>
                    {selectedPlan === plan.name && (
                      <div style={filledDotStyle}></div>
                    )}
                  </div>
                  <h2 className="text-lg md:text-xl font-semibold text-gray-800">
                    {plan.name}
                  </h2>
                  {plan.popular && (
                    <span style={popularButtonStyle}>Popular</span>
                  )}
                </div>
                <p style={priceStyleLeftAlign}>
                  {plan.price}
                  <span style={periodStyle}>{plan.period}</span>
                </p>
              </div>
              <p className="text-sm md:text-base font-semibold text-gray-900 mt-2">
                {plan.posts}
              </p>
            </button>
          ))}
          {/* Added Terms & Conditions Text with Links */}
          <p className="text-xs text-gray-500 mt-6 px-4">
            Pricing is in INR, auto-renews unless cancelled, and all services
            are non-refundable. By subscribing, you agree to the{' '}
            <a
              href="/terms-and-conditions"
              className="text-red-600 underline hover:text-red-800"
            >
              Terms & Conditions
            </a>{' '}
            and{' '}
            <a
              href="/refund-policy"
              className="text-red-600 underline hover:text-red-800"
            >
              Refund Policy
            </a>. You can cancel anytime.
          </p>
        </div>

        {/* Description Display on Right */}
        <div className="relative mt-4 md:mt-0 w-full md:w-2/5 md:ml-8 p-6 md:p-10 rounded-lg shadow-lg">
          {/* "Sign Up Now" button added before right section for mobile */}
          <div className="block md:hidden mb-6">
            <Link to="https://pages.razorpay.com/smmredsprout">
              <button style={buttonStyle} className="w-full md:w-auto">
                Sign Up Now!
              </button>
            </Link>
          </div>

          {plans
            .filter((plan) => plan.name === selectedPlan)
            .map((plan) => (
              <div key={plan.name}>
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center">
                    <h2 className="text-xl md:text-2xl font-bold text-black">
                      {plan.name}
                    </h2>
                    {plan.popular && (
                      <span style={popularButtonStyle}>Popular</span>
                    )}
                  </div>
                </div>
                <p style={priceStyleLeftAlign}>
                  {plan.price}
                  <span style={periodStyle}>{plan.period}</span>
                </p>
                <ul className="list-disc pl-6 space-y-2 text-left text-gray-700">
                  {plan.description.map((item, index) => (
                    <li key={index} className="mb-2">
                      <span className="text-xs md:text-sm font-medium">
                        {item}
                      </span>
                    </li>
                  ))}
                </ul>

                {/* Hide "Sign Up Now" button on mobile (visible on desktop only) */}
                <div className="hidden md:block mt-6">
                  <Link to="https://pages.razorpay.com/smmredsprout">
                    <button style={buttonStyle} className="w-full md:w-auto">
                      Sign Up Now!
                    </button>
                  </Link>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default PricingTabs;

// Keyframe animation for blinking effect
const styles = `
  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
`;

// Add styles to the document head
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);
