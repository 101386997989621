import React, { useState, useCallback } from 'react';
import axios from 'axios';

const jobs = [
  {
    title: 'Digital Content Writer',
    posted: '2 years ago',
    applications: 83,
    type: 'Full-time',
    location: 'On-site',
    salary: '~$100',
    city: 'Mumbai',
    details: [
      'First Page Digital prides itself on being a leader in SEO.',
      'Collaborate with a strong, dynamic team.',
      'Working side by side with talented individuals.'
    ]
  },
  {
    title: 'SEO Specialist',
    posted: '1 year ago',
    applications: 120,
    type: 'Full-time',
    location: 'Remote',
    salary: '~$200',
    city: 'Remote',
    details: [
      'Drive SEO strategy for high-profile clients.',
      'Work from anywhere in the world.',
      'Be part of an innovative marketing team.'
    ]
  },
  {
    title: 'UI/UX Designer',
    posted: '6 months ago',
    applications: 45,
    type: 'Part-time',
    location: 'On-site',
    salary: '~$300',
    city: 'New York, USA',
    details: [
      'Design user-friendly interfaces for web and mobile.',
      'Collaborate with cross-functional teams.',
      'Work in a fast-paced, creative environment.'
    ]
  },
  {
    title: 'Marketing Manager',
    posted: '3 months ago',
    applications: 60,
    type: 'Full-time',
    location: 'On-site',
    salary: '~$300',
    city: 'San Francisco, USA',
    details: [
      'Lead a team of passionate marketers.',
      'Develop and execute marketing strategies.',
      'Work in a dynamic, innovative company.'
    ]
  }
];

const JobListing = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [formData, setFormData] = useState({ name: '', email: '', phone: '', cv: null });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleApplyClick = useCallback((job) => {
    setSelectedJob(job);
    setShowModal(true);
    setSuccessMessage('');
    setErrorMessage('');
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    setSelectedJob(null);
  }, []);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }, [formData]);

  const handleFileChange = useCallback((e) => {
    setFormData({ ...formData, cv: e.target.files[0] });
  }, [formData]);

  const validateForm = () => {
    if (!formData.name || !formData.email || !formData.phone || !formData.cv) {
      setErrorMessage('All fields are required.');
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setErrorMessage('Please enter a valid email address.');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);  // Start loading state
    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('phone', formData.phone);
    formDataToSend.append('cv', formData.cv);
    formDataToSend.append('subject', `Job Application: ${selectedJob.title}`);  // Dynamic subject

    try {
      const response = await axios.post('https://5gno3n9tad.execute-api.us-east-1.amazonaws.com/dev/send-email', formDataToSend, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (response.status === 200) {
        setSuccessMessage('Your application has been submitted successfully!');
        setFormData({ name: '', email: '', phone: '', cv: null });
        setTimeout(() => handleCloseModal(), 3000);  // Close modal after success
      } else {
        setErrorMessage('Failed to submit the application. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error.response ? error.response.data : error.message);
      setErrorMessage('Failed to submit the application. Please try again.');
    } finally {
      setLoading(false);  // Stop loading state
    }
  };

  return (
    <div className="relative min-h-screen bg-gradient-to-tr from-red-100 via-white to-red-100 p-10">
      <div className={`container mx-auto transition-all duration-300 ${showModal ? 'blur-md' : ''}`}>
        <div className="text-gray-700 text-lg mb-6">Showing {jobs.length} of {jobs.length} jobs.</div>
        {jobs.map((job, index) => (
          <div key={index} className="border rounded-lg p-4 mb-4 shadow-sm bg-white bg-opacity-80">
            {/* Main container for mobile and desktop layout */}
            <div className="md:flex md:flex-col">
              {/* Job details and list (always on top) */}
              <div>
                <h2 className="text-xl font-bold text-gray-900">{job.title}</h2>
                <div className="text-gray-500 text-sm mb-2">
                  {job.posted} • <span className="font-bold">{job.applications} applications</span>
                </div>
                <div className="flex items-center text-sm mb-4 text-red-600">
                  <div className="mr-4"><i className="fas fa-briefcase"></i> {job.type}</div>
                  <div className="mr-4"><i className="fas fa-map-marker-alt"></i> {job.location}</div>
                  <div className="mr-4"><i className="fas fa-dollar-sign"></i> {job.salary}</div>
                  <div><i className="fas fa-location-arrow"></i> {job.city}</div>
                </div>
                <div className="text-gray-700">
                  <ul className="list-disc pl-6">
                    {job.details.map((detail, i) => (
                      <li key={i}>{detail}</li>
                    ))}
                  </ul>
                </div>
              </div>

              {/* Apply Now button (always at the bottom) */}
              <div className="mt-4 md:mt-6">
                <button
                  onClick={() => handleApplyClick(job)}
                  className="px-4 py-2 w-full md:w-auto bg-gradient-to-r from-red-500 via-red-600 to-red-700 text-white rounded-md shadow-md hover:from-red-600 hover:to-red-800 transition transform hover:scale-105 duration-300"
                >
                  Apply Now
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-8 w-full max-w-lg">
            <h2 className="text-2xl font-bold mb-4">Apply for {selectedJob.title}</h2>
            {successMessage && <p className="text-green-600 mb-4">{successMessage}</p>}
            {errorMessage && <p className="text-red-600 mb-4">{errorMessage}</p>}
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Your Name"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Your Email"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
                  Phone
                </label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Your Phone Number"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cv">
                  Upload Your CV (PDF)
                </label>
                <input
                  type="file"
                  id="cv"
                  name="cv"
                  accept=".pdf"  // Restrict file type to PDF
                  onChange={handleFileChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="flex justify-between items-center">
                <button
                  type="submit"
                  className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
                  disabled={loading}
                >
                  {loading ? 'Submitting...' : 'Submit Application'}
                </button>
                <button
                  type="button"
                  className="text-gray-600 hover:text-gray-900 transition duration-300"
                  onClick={handleCloseModal}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default JobListing;
