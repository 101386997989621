import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const TermsAndConditions = () => {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Terms and Conditions | RedSprout Digital</title>
          <meta
            name="description"
            content="Read the terms and conditions for using RedSprout Digital's website and services. Learn about your rights, responsibilities, and our commitment to your privacy and security."
          />
          <meta
            name="keywords"
            content="terms and conditions"
          />
          <meta property="og:title" content="Terms and Conditions | RedSprout Digital" />
          <meta property="og:description" content="Read the terms and conditions for using RedSprout Digital's website and services. Learn about your rights, responsibilities, and our commitment to your privacy and security." />
          <meta property="og:image" content="/Images/logo.webp?v=1" />
          <meta property="og:url" content="https://redsproutdigital.com/terms-and-conditions/" />
          <link rel="canonical" href="https://redsproutdigital.com/terms-and-conditions/" />
        </Helmet>

        <div className="relative bg-gradient-to-tl from-red-100 via-white to-red-100 py-16 overflow-hidden">
          <div className="container mx-auto px-4">
            <h1 className="text-4xl font-extrabold mb-4 text-center">Terms and Conditions</h1>
           
            <p className="mb-4 text-gray-600 text-left">Last updated: December 07, 2023</p>

            <h2 className="text-2xl font-bold mb-2">OVERVIEW</h2>
            <p className="mb-4">
              Welcome to RedSprout Digital! These are our straightforward terms and conditions. By using our website, you agree to follow these rules. Please read them carefully.
            </p>

            <h2 className="text-2xl font-bold mb-2">USING OUR SERVICES</h2>
            <p className="mb-4">
              You should be of legal age in your area to use our services, or you need permission from a guardian if you’re a minor.
            </p>
            <p className="mb-4">
              Don’t use our services for anything illegal and be sure to follow the law.
            </p>
            <p className="mb-4">
              Please don’t send viruses or harmful code to our site.
            </p>
            <p className="mb-4">
              If you break any of these rules, we can stop providing our services to you.
            </p>

            <h2 className="text-2xl font-bold mb-2">HOW TO USE OUR WEBSITE</h2>
            <p className="mb-4">
              We have the right to refuse service to anyone, for any reason.
            </p>
            <p className="mb-4">
              Information you share with us may not always be encrypted, but credit card info is always safe.
            </p>
            <p className="mb-4">
              Don’t copy, sell, or misuse any part of our service without our permission.
            </p>

            <h2 className="text-2xl font-bold mb-2">INFORMATION ACCURACY</h2>
            <p className="mb-4">
              The information on our website may not always be perfect. Use it as a general guide, but consult better sources for important decisions.
            </p>
            <p className="mb-4">
              Some info might be old. We don’t have to update it, but you can always check back.
            </p>

            <h2 className="text-2xl font-bold mb-2">CHANGES TO PRICES AND SERVICES</h2>
            <p className="mb-4">
              Prices for our products may change without notice.
            </p>
            <p className="mb-4">
              We can modify or stop our services at any time without notice.
            </p>

            <h2 className="text-2xl font-bold mb-2">PRODUCTS AND SERVICES</h2>
            <p className="mb-4">
              Some products are only available online, and they might have limited quantities. Read our return policy for more details.
            </p>
            <p className="mb-4">
              We try to show accurate colors and images, but your screen might not show them exactly.
            </p>
            <p className="mb-4">
              We can choose not to sell our products or services to certain people or areas. We can change product descriptions and prices anytime.
            </p>
            <p className="mb-4">
              We don’t guarantee the quality of our products, and we’re not responsible for errors on our website.
            </p>

            <h2 className="text-2xl font-bold mb-2">BILLING AND ACCOUNT INFO</h2>
            <p className="mb-4">
              We can refuse your order for various reasons which will definitely be discussed with you.
            </p>
            <p className="mb-4">
              Make sure your account info is accurate and up-to-date.
            </p>

            <h2 className="text-2xl font-bold mb-2">OPTIONAL TOOLS</h2>
            <p className="mb-4">
              We offer some third-party tools, but they come with no guarantees. Use them at your own risk.
            </p>
            <p className="mb-4">
              We might add new services and features in the future, and they will also follow these terms.
            </p>

            <h2 className="text-2xl font-bold mb-2">LINKS TO OTHER WEBSITES</h2>
            <p className="mb-4">
              We can’t control other websites we link to. We’re not responsible for their content or any harm they cause. Be cautious and understand their terms before you use them.
            </p>

            <h2 className="text-2xl font-bold mb-2">COMMENTS AND FEEDBACK</h2>
            <p className="mb-4">
              If you send us comments, we can use them however we like without owing you anything.
            </p>
            <p className="mb-4">
              We may remove or edit comments we think are inappropriate.
            </p>

            <h2 className="text-2xl font-bold mb-2">PRIVACY</h2>
            <p className="mb-4">
              We handle your personal info as described in our Privacy Policy.
            </p>

            <h2 className="text-2xl font-bold mb-2">ERRORS AND OMISSIONS</h2>
            <p className="mb-4">
              We might have errors on our website, and we can change things without telling you in advance.
            </p>

            <h2 className="text-2xl font-bold mb-2">USE RESTRICTIONS</h2>
            <p className="mb-4">
              Please don’t use our website for anything illegal, harmful, or annoying. Don’t try to break our security measures.
            </p>

            <h2 className="text-2xl font-bold mb-2">WARRANTIES AND LIABILITY</h2>
            <p className="mb-4">
              We can’t guarantee our service will always work perfectly.
            </p>
            <p className="mb-4">
              If something goes wrong while using our service, we can sort the way out to come to a mutual decision.
            </p>

            <h2 className="text-2xl font-bold mb-2">INDEMNIFICATION</h2>
            <p className="mb-4">
              You have to cover our costs if you violate these terms or the law.
            </p>

            <h2 className="text-2xl font-bold mb-2">SEVERABILITY</h2>
            <p className="mb-4">
              If one part of these terms isn’t enforceable, the rest still apply.
            </p>

            <h2 className="text-2xl font-bold mb-2">TERMINATION</h2>
            <p className="mb-4">
              You or we can end these terms anytime. If you break the terms, we can stop providing our services without notice.
            </p>

            <h2 className="text-2xl font-bold mb-2">ENTIRE AGREEMENT</h2>
            <p className="mb-4">
              These terms are the whole deal between you and us. Any earlier agreements don’t matter.
            </p>

            <h2 className="text-2xl font-bold mb-2">GOVERNING LAW</h2>
            <p className="mb-4">
              These terms follow the laws of India and the jurisdiction of Delhi.
            </p>

            <h2 className="text-2xl font-bold mb-2">CHANGES TO TERMS</h2>
            <p className="mb-4">
              These terms might change, and it’s your job to keep up with them.
            </p>

            <h2 className="text-2xl font-bold mb-2">CONTACT</h2>
            <p className="mb-4">
              If you have any questions about these terms, reach out to us at <a href="mailto:info@redsproutdigital.com" className="text-blue-600 hover:underline">info@redsproutdigital.com</a>
            </p>
          </div>
          
          <div className="container mx-auto px-4">
            <p className="mb-4">RedSprout Digital provides affordable social media management services as described on our website (https://redsproutdigital.com/). By signing up, you confirm that you have read and understood our terms and conditions.</p>
            <h2 className="text-2xl font-bold mb-2">COLLABORATION & COMMUNICATION</h2>
            <p className="mb-4">
              <strong>Service Delivery:</strong> We aim to deliver high-quality services promptly. Each client is assigned an account manager for ongoing communication. Timely feedback is essential for success. Once deliverables are approved, no further revisions will be made.
            </p>
            <p className="mb-4">
              <strong>Social Media Account Issues:</strong> We cannot control third-party platform issues such as disconnections or password changes. However, we will assist with any technical issues within our control. For issues beyond our scope, please contact the platform directly.
            </p>
            <p className="mb-4">
              <strong>Scheduling & Posting:</strong> RedSprout cannot schedule or post content until at least one platform is connected to our system. If connections cannot be established, you can download and manually post the content. Posting and scheduling are free, added-value services; no refunds are provided for connection issues.
            </p>
            <p className="mb-4">
              <strong>Support:</strong> Due to our high-volume services, we offer support through our dashboard, live chat, or email only—no phone calls.
            </p>
            <p className="mb-4">
              <strong>Client Delays:</strong> Service delivery begins once you sign up and complete our onboarding questionnaire. Delays on your part do not warrant a refund. Payments will be converted into credits for future use, without expiration. We do not shift billing dates due to client delays, and the next round of services will begin with the next charge.
            </p>
            <p className="mb-4">
              <strong>Lack of Feedback:</strong> Timely feedback is required to avoid outdated content. If feedback is not received, the next round of content creation will proceed, and no revisions will be accepted for content over 30 days old.
            </p>
            <p className="mb-4">
              <strong>Respectful Communication:</strong> We expect professional and respectful communication. Clients who are rude or disrespectful will have their accounts canceled immediately without refund.
            </p>
            <p className="mb-4">
              <strong>Best Practice:</strong> Notify us immediately of any password changes. Provide clear feedback and work with us consistently to achieve the best results.
            </p>

            <h2 className="text-2xl font-bold mb-2">REFUND POLICY</h2>
            <p className="mb-4">
              By subscribing, you acknowledge that all services are non-refundable. Posting and scheduling are free added services, and no refunds will be provided for issues related to this.
            </p>

            <h2 className="text-2xl font-bold mb-2">REDSPROUT DIGITAL OBLIGATIONS</h2>
            <p className="mb-4">
              We provide services according to our standard policies and procedures. We reserve the right to adjust our policies, pricing, and package inclusions at our discretion.
            </p>
            <p className="mb-4">
              Fees are payable in advance. Services continue until canceled, and unpaid charges will result in service suspension. Refunds are not issued, but credits or billing adjustments may be offered for mistakes on our part.
            </p>

            <h2 className="text-2xl font-bold mb-2">WARRANTY DISCLAIMERS</h2>
            <p className="mb-4">
              We do not guarantee specific results, growth, or satisfaction. You agree to accept credits for any undelivered posts. All services are final, and no refunds will be provided.
            </p>

            <h2 className="text-2xl font-bold mb-2">INDEMNITY</h2>
            <p className="mb-4">
              You agree to indemnify RedSprout from any liabilities arising from breaches, third-party platform issues, or intellectual property matters.
            </p>

            <h2 className="text-2xl font-bold mb-2">LIMITATION OF LIABILITY</h2>
            <p className="mb-4">
              Our liability is limited to the amount paid for the previous month’s services.
            </p>

            <h2 className="text-2xl font-bold mb-2">CONFIDENTIALITY AGREEMENT (NDA)</h2>
            <p className="mb-4">
              We commit to protecting any confidential information shared with us during service provision. Confidentiality will be maintained throughout the service period and for two years afterward.
            </p>

            <h2 className="text-2xl font-bold mb-2">CREDIT CARD DISPUTES</h2>
            <p className="mb-4">
              Credit card disputes must be raised within 14 days. Disputing a charge through your bank may still result in an obligation to pay RedSprout Digital directly. Legal action will follow if the invoice remains unpaid.
            </p>

            <p className="mb-4">
              For any questions or clarifications. Reach out to us at <a href="mailto:info@redsproutdigital.com" className="text-blue-600 hover:underline">info@redsproutdigital.com</a>
            </p>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
};

export default TermsAndConditions;
