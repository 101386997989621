import React from 'react';

const CTASection = () => {
  return (
    <div className="bg-[#0a0c12] py-16">
      <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center text-center md:text-left">
        {/* Left Text Section */}
        <div className="mb-6 md:mb-0">
          <h3 className="text-sm uppercase text-gray-400 mb-2">Let's Talk</h3>
          <h2 className="text-4xl md:text-5xl font-bold text-white leading-tight">
            Not sure what will help <br className="block" />
            <span>your business?</span>
          </h2>
        </div>

        {/* Right Button Section */}
        <div>
        <a href="https://calendly.com/digitalfuture7/30min" target="_blank" rel="noopener noreferrer">
          <button
            className="border border-white text-white py-3 px-6 rounded-md flex items-center transition-transform duration-300 hover:bg-white hover:text-black"
          >
            Get Free 30 min Consultation
            <span className="ml-2 text-lg">→</span>
          </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default CTASection;
