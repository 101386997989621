import React from 'react';

const ServicesSection = () => {
  return (
    <div className="bg-gradient-to-br from-red-100 via-white to-red-100 py-16">
      <div className="container mx-auto px-4">
        {/* Heading Section */}
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold text-black mb-4">Our Services</h2>
          <p className="text-lg text-gray-700">We offer a wide range of services to meet your business needs and help you grow</p>
        </div>

        {/* Service Boxes */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-[2px]">
          {/* Service Block 1 */}
          <div className="p-8 bg-white text-black shadow-md h-full flex flex-col justify-between border border-gray-200 transform transition duration-500 hover:rotate-1 hover:shadow-lg">
            <div>
              <h3 className="text-sm font-semibold mb-2 text-black">Data & Analytics</h3>
              <h4 className="text-3xl font-bold mb-4 leading-tight text-black">Digital Reporting and Custom Dashboards</h4>
              <p className="text-base leading-relaxed text-gray-700">Our digital reporting and custom dashbaords turns complex information into clear, actionable insights, empowering you to make faster, better decisions with confidence.</p>
            </div>
            <div className="mt-4">
              <button className="border border-black text-black py-2 px-4 rounded-md transition-transform duration-300 hover:bg-black hover:text-white transform hover:scale-105">
                Learn More
              </button>
            </div>
          </div>

          {/* Service Block 2 */}
          <div className="p-8 bg-white text-black shadow-md h-full flex flex-col justify-between border border-gray-200 transform transition duration-500 hover:rotate-1 hover:shadow-lg">
            <div>
              <h3 className="text-sm font-semibold mb-2 text-black">Creative Solutions</h3>
              <h4 className="text-3xl font-bold mb-4 leading-tight text-black">Creatives Driving Meaningful Solutions</h4>
              <p className="text-base leading-relaxed text-gray-700">We offer comprehensive creative content solutions, including engaging social media posts, video production, copywriting, graphic design, and more.</p>
            </div>
            <div className="mt-4">
              <button className="border border-black text-black py-2 px-4 rounded-md transition-transform duration-300 hover:bg-black hover:text-white transform hover:scale-105">
                Learn More
              </button>
            </div>
          </div>

          {/* Service Block 3 */}
          <div className="p-8 bg-white text-black shadow-md h-full flex flex-col justify-between border border-gray-200 transform transition duration-500 hover:rotate-1 hover:shadow-lg">
            <div>
              <h3 className="text-sm font-semibold mb-2 text-black">Lead Generation</h3>
              <h4 className="text-3xl font-bold mb-4 leading-tight text-black">Google Ads, Meta Ads & YouTube Ads</h4>
              <p className="text-base leading-relaxed text-gray-700">Maximize visibility and generate leads with Google Ads, Meta Ads, YouTube Ads through targeted, ROI-driven campaigns designed to engage your audience. </p>
            </div>
            <div className="mt-4">
              <button className="border border-black text-black py-2 px-4 rounded-md transition-transform duration-300 hover:bg-black hover:text-white transform hover:scale-105">
                Learn More
              </button>
            </div>
          </div>

          {/* Service Block 4 */}
          <div className="p-8 bg-white text-black shadow-md h-full flex flex-col justify-between border border-gray-200 transform transition duration-500 hover:rotate-1 hover:shadow-lg">
            <div>
              <h3 className="text-sm font-semibold mb-2 text-black">E-commerce Solutions</h3>
              <h4 className="text-3xl font-bold mb-4 leading-tight text-black">Transform Your Online Store</h4>
              <p className="text-base leading-relaxed text-gray-700">Develop your e-commerce platform to sell products directly to customers. Our solutions optimize user experience, streamline transactions, and expand your reach.</p>
            </div>
            <div className="mt-4">
              <button className="border border-black text-black py-2 px-4 rounded-md transition-transform duration-300 hover:bg-black hover:text-white transform hover:scale-105">
                Learn More
              </button>
            </div>
          </div>

          {/* Service Block 5 */}
          <div className="p-8 bg-white text-black shadow-md h-full flex flex-col justify-between border border-gray-200 transform transition duration-500 hover:rotate-1 hover:shadow-lg">
            <div>
              <h3 className="text-sm font-semibold mb-2 text-black">SEO & Marketing</h3>
              <h4 className="text-3xl font-bold mb-4 leading-tight text-black">Drive Traffic and Increase Sales</h4>
              <p className="text-base leading-relaxed text-gray-700">Our SEO and marketing strategies will help drive organic traffic, increase conversions, and grow your business online efficiently, effectively, and sustainably.</p>
            </div>
            <div className="mt-4">
              <button className="border border-black text-black py-2 px-4 rounded-md transition-transform duration-300 hover:bg-black hover:text-white transform hover:scale-105">
                Learn More
              </button>
            </div>
          </div>

          {/* Service Block 6 */}
          <div className="p-8 bg-white text-black shadow-md h-full flex flex-col justify-between border border-gray-200 transform transition duration-500 hover:rotate-1 hover:shadow-lg">
            <div>
              <h3 className="text-sm font-semibold mb-2 text-black">Brand Strategy</h3>
              <h4 className="text-3xl font-bold mb-4 leading-tight text-black">Building Your Brand Identity</h4>
              <p className="text-base leading-relaxed text-gray-700">We help define your brand and create a unique identity that stands out in the market, driving customer engagement, loyalty, and long-term success.</p>
            </div>
            <div className="mt-4">
              <button className="border border-black text-black py-2 px-4 rounded-md transition-transform duration-300 hover:bg-black hover:text-white transform hover:scale-105">
                Learn More
              </button>
            </div>
          </div>
        </div>

        {/* Footer Section */}
        <div className="mt-16 text-center mb-12">
          <p className=" text-gray-700 text-justify">
            At RedSprout Digital, we are the premier digital marketing agency dedicated to driving your business growth through a comprehensive range of services. Our expertise spans advertising and digital marketing, combining PPC in digital marketing, SEO strategies, and web development to create impactful campaigns that boost your online presence. Whether it’s optimizing your website for better visibility or crafting targeted ads to engage your audience, we deliver tailored solutions that lead to measurable success.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
