import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import Header from './components/pages/Header';
import Home from './components/pages/Home';  
import Footer from "./components/pages/Footer";  
import WhoWeAre from './components/pages/WhoWeAre';  
import WhyChooseUs from './components/pages/WhyChooseUs';
import Careers from './components/pages/Careers'; 
import WebsiteDevelopment from './components/pages/WebsiteDevelopment';
import Seo from './components/pages/Seo';
import GoogleAds from './components/pages/GoogleAds'; 
import IndustriesWeServe from './components/pages/IndustriesWeServe';  
import ContactUs from './components/pages/ContactUs';  
import GetStarted from './components/pages/GetStarted';
import DigitalMarketingAgencyinAustralia from './components/pages/DigitalMarketingAgencyinAustralia';  
import DigitalMarketingAgencyinCanada from './components/pages/DigitalMarketingAgencyinCanada';  
import DigitalMarketingAgencyinSingapore from './components/pages/DigitalMarketingAgencyinSingapore';  
import DigitalMarketingAgencyinUK from './components/pages/DigitalMarketingAgencyinUK';  
import DigitalMarketingAgencyinUSA from './components/pages/DigitalMarketingAgencyinUSA';
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import TermsAndConditions from "./components/pages/TermsAndConditions";  
import ScrollToTop from './components/pages/ScrollToTop'; 
import NoFoundPage from './components/pages/NoFoundPage';
import BlogList from './components/pages/BlogList';
import BlogPostPage from './components/pages/BlogPostPage';
import AdminLogin from './components/pages/AdminLogin';
import AdminDashboard from './components/pages/AdminDashboard';
import ProtectedRoute from './components/pages/ProtectedRoute';
import ThankYou from './components/pages/ThankYou';
import ThankYouPage from './components/pages/ThankYou';
import Pricing from './components/pages/Pricing';
import RefundPolicy from "./components/pages/RefundPolicy";
import IndiaPricing from "./components/pages/IndiaPricing";



const App = () => {
  return (
    <HelmetProvider>
      <Router>
        <div className="flex flex-col min-h-screen">
          <Header />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/who-we-are" element={<WhoWeAre />} />
              <Route path="/why-choose-us" element={<WhyChooseUs />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/website-development" element={<WebsiteDevelopment />} />
              <Route path="/seo" element={<Seo />} />
              <Route path="/google-ads" element={<GoogleAds />} />
              <Route path="/industries-we-serve" element={<IndustriesWeServe />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/get-started" element={<GetStarted />} />
              <Route path="/digital-marketing-agency-in-australia" element={<DigitalMarketingAgencyinAustralia />} />
              <Route path="/digital-marketing-agency-in-canada" element={<DigitalMarketingAgencyinCanada />} />
              <Route path="/digital-marketing-agency-in-singapore" element={<DigitalMarketingAgencyinSingapore />} />
              <Route path="/digital-marketing-agency-in-uk" element={<DigitalMarketingAgencyinUK />} />
              <Route path="/digital-marketing-agency-in-usa" element={<DigitalMarketingAgencyinUSA />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="/blog" element={<BlogList />} />
              <Route path="/blog/:id" element={<BlogPostPage />} />
              <Route path="/admin" element={<AdminLogin />} />
              <Route path="/admin/dashboard" element={<ProtectedRoute element={AdminDashboard} />} />
              <Route path="*" element={<NoFoundPage />} />
              <Route path="/thank-you" element={<ThankYou />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/refund-policy" element={<RefundPolicy />} />
              <Route path="/in-pricing" element={<IndiaPricing />} />
         
            </Routes>
          </main>
          <Footer />
          <ScrollToTop />
        </div>
      </Router>
    </HelmetProvider>
  );
};

export default App;
